import { queryOptions, useQuery } from '@tanstack/react-query';
import axios from '../axios';
import { ENTITY } from './entity';
import { IPagination, IPaginationForRQ, TBaseEventSchema, TError } from '@onesource/schemas';

type TParams = {
  enabled?: boolean;
};

export const getAllEventsQueryKey = () => {
  return [ENTITY];
};

export const getAllEventsQueryOptions = (params?: TParams) => {
  const { enabled = true } = params || {};
  return queryOptions<IPaginationForRQ<TBaseEventSchema>, TError>({
    queryKey: getAllEventsQueryKey(),
    queryFn: async () => {
      const res = await axios.get<IPagination<TBaseEventSchema>>(ENTITY);
      return res.data.data;
    },
    enabled,
    refetchOnWindowFocus: false,
  });
};
export type TGetAllEventsQueryOptionsReturn = ReturnType<typeof getAllEventsQueryOptions>;

export const useGetAllEventsQuery = (params?: TParams) => {
  return useQuery(getAllEventsQueryOptions(params));
};
export type TGetAllEventsQueryReturn = ReturnType<typeof useGetAllEventsQuery>;
