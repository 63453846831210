import { alpha, darken, SxProps, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import MuiLink from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Facebook, Instagram, Linkedin, Location, Website } from '@onesource/icons';
import { useGetContentsQuery, useGetInstructorQuery } from '@onesource/services';
import { Link } from '@tanstack/react-router';
import { FC } from 'react';
import { Breadcrumbs } from '../../breadcrumbs';
import { RelatedPosts } from '../related-posts';
import { InstructorDetailsLoading } from './loading';
import { NotFoundCard } from '@onesource/components';
import { BackButton } from '@onesource/components';
import { EAppNameEnum, MediaTypeEnum } from '@onesource/schemas';
import { PiTiktokLogo, PiTwitterLogo, PiYoutubeLogo } from 'react-icons/pi';

export type TSingleTeacherDetailsProps = {
  instructorId: string;
  sx?: SxProps;
};
export const InstructorDetailsBody: FC<TSingleTeacherDetailsProps> = props => {
  const { instructorId, sx } = props;
  const theme = useTheme();

  const { data: instructor, isLoading } = useGetInstructorQuery({ instructorId });

  const { data: instructorContent } = useGetContentsQuery({
    app: EAppNameEnum.FIT,
    filters: { instructorIds: instructorId },
  });

  if (isLoading) {
    return <InstructorDetailsLoading />;
  }

  if (!instructor) {
    return null;
  }
  const instructorName = `${instructor?.firstName} ${instructor?.lastName}`;

  const pointStyle = {
    color: { xs: darken(theme.palette.common.white, 0.799), md: theme.palette.common.white },
    borderLeft: { xs: `3px solid ${theme.palette.primary.main}`, md: `3px solid` },
    fontSize: { xs: '18px', md: '22px' },
    lineHeight: 1.5,
    fontWeight: { xs: 400, md: 500 },
    pl: 2,
    mt: { xs: 1.5, md: 0 },
    mb: { xs: 1.5, md: 0 },
    width: '180px',
  };

  const SliderSettings = {
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    infinite: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: false,
          infinite: true,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: false,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '15%',
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '5%',
          infinite: true,
        },
      },
    ],
  };

  const linkedin = MediaTypeEnum.linkedin;
  const facebook = MediaTypeEnum.facebook;
  const instagram = MediaTypeEnum.instagram;
  const twitter = MediaTypeEnum.twitter;
  const tiktok = MediaTypeEnum.tiktok;
  const youtube = MediaTypeEnum.youtube;
  const website = MediaTypeEnum.website;

  return (
    <>
      <Box sx={{ ...sx, overflowX: 'hidden' }}>
        <Container maxWidth="xl">
          <Grid
            container
            spacing={14}
            sx={{
              background: theme => theme.palette.common.white,
              ...sx,
            }}
          >
            <Grid item md={6} xs={12} sx={{ mb: '34px' }}>
              <Box sx={{ pb: { xs: 2, md: 13 }, mt: 4 }}>
                <Breadcrumbs>
                  <BackButton color="black" />
                  <Link style={{ textDecoration: 'none' }} to="/instructors">
                    Instructors
                  </Link>
                  <Typography variant="body1" sx={{ color: `${theme.palette.primary.main}!important` }}>
                    {instructorName}
                  </Typography>
                </Breadcrumbs>
              </Box>
              <Typography
                variant="h2"
                sx={{
                  mb: 3,
                  textAlign: { xs: 'center', md: 'left' },
                  fontSize: { xs: '30px', md: '48px' },
                  fontWeight: '600',
                }}
              >
                {instructorName}
              </Typography>
              <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center', position: 'relative' }}>
                <Box
                  sx={{
                    position: 'absolute',
                    zIndex: 0,
                    background: alpha(theme.palette.primary.main, 0.1),
                    width: '100%',
                    height: '100%',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: '50%',
                    borderRadius: '30px 0 0 30px',
                  }}
                />
                <Card sx={{ pt: 3, pb: 3, background: 'transparent', position: 'relative', zIndex: 1, boxShadow: 0 }}>
                  {instructor?.profilePicture?.landscape && (
                    <CardMedia component="img" image={instructor.profilePicture.landscape} sx={{ borderRadius: 7 }} />
                  )}
                </Card>
              </Box>
              <Box>
                {instructor.role && (
                  <Typography
                    variant="body1"
                    sx={{
                      mb: 2,
                      color: theme.palette.primary.main,
                    }}
                  >
                    <span dangerouslySetInnerHTML={{ __html: instructor.role }} />
                  </Typography>
                )}

                <Stack direction="row" spacing={1}>
                  <Location fontSize={25} color={theme.palette.primary.main} />

                  <Typography
                    variant="body2"
                    sx={{
                      mb: 3,
                      color: darken(theme.palette.common.white, 0.799), //charcol color
                      lineHeight: 1.8,
                    }}
                  >
                    {instructor.location}
                  </Typography>
                </Stack>

                <Divider sx={{ mb: 3, mt: 2.5 }} />

                <Typography
                  variant="body2"
                  sx={{
                    color: darken(theme.palette.common.white, 0.799),
                    lineHeight: 1.8,
                  }}
                >
                  {instructor.bio && (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: instructor.bio,
                      }}
                    />
                  )}
                </Typography>
                {instructor.socialMedia && instructor.socialMedia.length > 0 && (
                  <Grid container spacing={2} alignItems="center" mt={3}>
                    {instructor.socialMedia.map((item, index) => {
                      if (!item.url) {
                        return null;
                      }
                      return (
                        <Grid key={index} item>
                          <MuiLink key={index} color="primary" href={item.url} target="_blank">
                            {item.mediaType === instagram && <Instagram style={{ fontSize: '28px' }} />}
                            {item.mediaType === facebook && <Facebook style={{ fontSize: '28px' }} />}
                            {item.mediaType === linkedin && <Linkedin style={{ fontSize: '28px' }} />}
                            {item.mediaType === twitter && <PiTwitterLogo style={{ fontSize: '28px' }} />}
                            {item.mediaType === tiktok && <PiTiktokLogo style={{ fontSize: '28px' }} />}
                            {item.mediaType === youtube && <PiYoutubeLogo style={{ fontSize: '32px' }} />}
                            {item.mediaType === website && <Website style={{ fontSize: '28px' }} />}
                          </MuiLink>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
              </Box>
            </Grid>
            <Grid item md={6} sx={{ position: 'relative', display: { xs: 'none', md: 'block' } }}>
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: 0,
                  background: alpha(theme.palette.primary.main, 0.1),
                  width: '100%',
                  height: '100%',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: '50%',
                }}
              />
              <Card sx={{ pt: 30, pb: 25, background: 'transparent', position: 'relative', zIndex: 1, boxShadow: 0 }}>
                {instructor?.profilePicture?.landscape && (
                  <CardMedia component="img" image={instructor.profilePicture.landscape} sx={{ borderRadius: 7 }} />
                )}
              </Card>
            </Grid>
          </Grid>
        </Container>
        {instructor.strongPoints && instructor.strongPoints.length > 0 && (
          <Box sx={{ background: { xs: alpha(theme.palette.primary.light, 0.12), md: theme.palette.primary.main } }}>
            <Container maxWidth="xl">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: { xs: 'flex-start', md: 'center' },
                  justifyContent: 'space-between',
                  flexDirection: { xs: 'column', md: 'row' },
                  pt: { xs: 2, md: 6 },
                  pb: { xs: 2, md: 6 },
                }}
              >
                {instructor.strongPoints.slice(0, 3).map((point, index) => (
                  <Typography sx={pointStyle} key={index}>
                    {point}
                  </Typography>
                ))}
              </Box>
            </Container>
          </Box>
        )}
        <Box sx={{ background: theme.palette.grey[100], py: { xs: 5, md: 10 } }}>
          <Container maxWidth="xl">
            {instructorContent?.items.length ? (
              <RelatedPosts
                contents={instructorContent.items}
                sx={{ background: 'transparent' }}
                actionButton={{
                  children: `See all ${instructor.firstName}’s Classes`,
                  href: `/classes/by-teachers?instructorIds="${instructorId}"`,
                }}
                sliderSettings={SliderSettings}
              />
            ) : (
              <NotFoundCard entity="videos" sx={{ mb: 10 }} />
            )}
          </Container>
        </Box>
        {/* <Box sx={{ pt: 11 }}>
          <Container maxWidth="xl">
            <Typography variant="h2" sx={{ mb: 6, textAlign: 'center' }}>
              {instructorName + "'s Programs"}
            </Typography>
            <Box
              sx={{
                width: { xs: '100%', md: '50%' },
                m: 'auto',
                '& .slick-track>div>div': { margin: 'auto' },
                '& .slick-track': {
                  gap: 4,
                  display: 'flex',
                  alignItems: 'center',
                },
              }}
            >
              <Slideshow
                slides={slides}
                renderSlide={fitnessItem => <FitnessSeries fitnessItem={fitnessItem} key={fitnessItem.id} />}
                settings={SliderSettings}
              />
              <Box sx={{ mb: 8, mt: 7, textAlign: 'center' }}>
                <Button
                  endIcon={
                    <Box
                      sx={{
                        background: theme.palette.primary.main,
                        borderRadius: '100%',
                        padding: '14px',
                        lineHeight: 0,
                      }}
                    >
                      <SlArrowRight style={{ height: '17px', width: '17px' }} />
                    </Box>
                  }
                >
                  See all {instructor.firstName} Programs
                </Button>
              </Box>
            </Box>
          </Container>
        </Box> */}
      </Box>
    </>
  );
};
