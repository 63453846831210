import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Energy, Sweat } from '@onesource/icons';
import { IndicatorEnum, TIndicator } from '@onesource/schemas';
import { FC, useMemo } from 'react';
import { DialogTitle } from '../dialog-title';
import { SlEyeglass } from 'react-icons/sl';

type TAddRatingDialogProps = {
  open: boolean;
  onClose: () => void;
};

const ratingsInfo = [
  {
    title: 'Difficulty',
    indicator: IndicatorEnum.difficulty,
    description:
      'Measures how challenging a class is, from relaxed to intense. Higher ratings are for those seeking a more advanced, demanding workout, while lower ratings suit gentler sessions.',
  },
  {
    title: 'Intensity',
    indicator: IndicatorEnum.intensity,
    description:
      'Indicates how heart-pumping and sweat-inducing the class is. Higher ratings indicate faster-paced and more energizing workouts, while lower ratings suggest a more moderate pace.',
  },
  {
    title: 'Nerdiness',
    indicator: IndicatorEnum.nerdiness,
    description:
      'Highlights the educational aspect of the class. Higher ratings signify classes prioritizing education to enhance body awareness and understanding of movement patterns and preferences.',
  },
];

export const RatingInfoDialog: FC<TAddRatingDialogProps> = props => {
  const theme = useTheme();
  const { open, onClose } = props;

  const iconList: Record<TIndicator, JSX.Element> = useMemo(() => {
    return {
      difficulty: <Energy fontSize={26} color={theme.palette.primary.main} />,
      intensity: <Sweat fontSize={26} color={theme.palette.primary.main} />,
      nerdiness: <SlEyeglass fontSize={26} color={theme.palette.primary.main} />,
    } as const;
  }, [theme.palette.primary.main]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle onClose={onClose}>Ratings</DialogTitle>
      <DialogContent>
        <Grid container spacing={4} py={2}>
          {ratingsInfo.map((info, index) => (
            <Grid key={info.title} item xs={12} md={4}>
              <Stack
                direction="column"
                alignItems="start"
                sx={{
                  px: 1,
                  borderRight: theme =>
                    ratingsInfo.length - 1 === index ? 'none' : `1px solid ${theme.palette.grey[400]}`,
                }}
              >
                <Box pb={2}>{iconList[info.indicator]}</Box>
                <Box>
                  <Typography gutterBottom variant="body1">
                    *{index + 1}. {info.title}:
                  </Typography>
                  <Typography variant="body2" sx={{ color: theme.palette.grey[800], lineHeight: 2, fontSize: '14px' }}>
                    {info.description}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
