import Grid from '@mui/material/Grid';
import { FC, useCallback, useMemo, useState } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { ClassCalender } from '@onesource/components/src/form-elements/class-calender';
import {
  getFirstAndLastDayOfMonth,
  useGetContentsByDateRangeQuery,
  useGetSubscriptionQuery,
} from '@onesource/services';
import { ContentForToday } from '../../components/content-for-today';
import { EAppNameEnum, TContentByDate } from '@onesource/schemas';
import { DataStateHandler, HorizontalPost, HorizontalPostLoading, useGetByDevice } from '@onesource/components';
import { DateRange } from 'react-day-picker';
import { DateTime } from 'luxon';
import { ClassesHeader } from 'components/sections/classes-header';

export const ClassesByDate: FC = () => {
  const { isMobile, isXsMobile, isTablet } = useGetByDevice();

  const { now, initialState } = useMemo(() => {
    const now = DateTime.now();
    const { page } = getFirstAndLastDayOfMonth(now.year, now.month);
    return {
      now: now.toJSDate(),
      initialState: {
        page,
        start: DateTime.fromISO(now.toISO()).minus({ days: 6 }).toJSDate(),
        end: now.toJSDate(),
      },
    };
  }, []);

  const [page, setPage] = useState(1);
  const [date, setDate] = useState<DateRange | undefined>({
    from: initialState.start,
    to: now,
  });

  const queryResult = useGetContentsByDateRangeQuery(
    {
      startDate: date?.from?.toISOString() ?? null,
      endDate: date?.to?.toISOString() ?? null,
      page,
    },
    EAppNameEnum.FIT,
  );

  const { isFitSubscriptionActive } = useGetSubscriptionQuery();

  const isLocked = useMemo(() => !isFitSubscriptionActive, [isFitSubscriptionActive]);

  const handleDateChange = useCallback(
    (datetime: DateRange | undefined) => {
      const from = datetime?.from ?? initialState.start;
      const to = datetime?.to ?? now;
      setDate(prev => {
        const currentFrom = DateTime.fromJSDate(from);
        const prevFrom = DateTime.fromJSDate(prev?.from ?? initialState.start);

        const isLess = currentFrom.startOf('day') < prevFrom.startOf('day');
        const dayMinus6 = DateTime.fromJSDate(isLess ? currentFrom.toJSDate() : to)
          .minus({ days: 6 })
          .toJSDate();
        return { from: dayMinus6, to: isLess ? currentFrom.toJSDate() : to };
      });
    },
    [now],
  );

  const onNext = useCallback((page: number) => {
    setPage(page);
  }, []);

  const onPrev = useCallback((page: number) => {
    setPage(page);
  }, []);

  return (
    <>
      <ClassesHeader />
      <Box sx={{ overflowX: 'hidden' }}>
        <Container maxWidth="xl">
          <Grid container spacing={{ xs: 2, md: 4, lg: 8 }} mb={10}>
            <ContentForToday />
            <Grid item lg={5} xl={4} md={5} sm={6} xs={12} mb={{ xs: 5, md: 0 }}>
              <ClassCalender
                date={date}
                handleDateChange={handleDateChange}
                size={isXsMobile ? 'small' : isMobile ? 'medium' : isTablet ? 'small' : 'large'}
              />
            </Grid>
            <Grid item lg={7} xl={8} md={7} sm={6} xs={12}>
              <DataStateHandler
                queryResult={queryResult}
                onNext={onNext}
                onPrev={onPrev}
                entity="Classes by date"
                gridProps={{ xs: 12 }}
                loadingProps={{
                  loadingItemProps: { xs: 12 },
                }}
                pagination={false}
                LoadingComponent={HorizontalPostLoading}
                IterativeComponent={(props: { item: TContentByDate }) => (
                  <HorizontalPost
                    linkProps={{
                      search: {
                        date: props.item.createdAt.toString(),
                      },
                    }}
                    isLocked={isLocked}
                    contentWithDate={props.item}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
