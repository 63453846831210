import { FC, memo } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import { PostLoading } from '@onesource/components';

export const SeriesDetailsLoading: FC = memo(() => {
  return (
    <Container maxWidth="xl">
      <Grid container spacing={5} sx={{ mt: { xs: 4, md: 8 } }}>
        <Grid md={6} xs={12} item>
          <Skeleton height={80} />
        </Grid>
        <Grid md={6} xs={12} item>
          <Skeleton height={20} />
          <Skeleton height={20} />
          <Skeleton height={20} />
          <Skeleton height={20} />
          <Skeleton height={20} />

          <Divider sx={{ my: 2 }} />
        </Grid>
        <Grid item xs={12} sx={{ my: { xs: 5, md: 10 } }}>
          <Grid container justifyContent="center" spacing={3}>
            {Array.from({ length: 8 }).map((_x, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <PostLoading />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
});
