import { darken, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { AddToCalender, Calender, Clock, MvPlay, NewBadge } from '@onesource/icons';
import { IndicatorEnum, TContentByDate } from '@onesource/schemas';
import { ellipsis, formatDate } from '@onesource/utils';
import { Link, LinkProps } from '@tanstack/react-router';
import { FC } from 'react';
import { ContentCategoryBadge } from '../../content-category-badge';
import { useGetByDevice } from '../../hooks';
import { LockedCardOverlay } from '../../overlays/locked-card';
import { RequirementIndicator } from '../../requirement-indicator';
import { HorizontalPostMobileView } from './mobile-view';

type IProps = {
  contentWithDate: TContentByDate;
  isLocked: boolean;
  linkProps?: LinkProps;
  disablePlayButton?: boolean;
};

export const HorizontalPost: FC<IProps> = props => {
  const { contentWithDate, isLocked, linkProps, disablePlayButton = false } = props;
  const { content, activeOn } = contentWithDate;
  const { category, equipments, averageRating } = content;
  const theme = useTheme();
  const { isPortable } = useGetByDevice();

  if (isPortable) {
    return <HorizontalPostMobileView contentWithDate={contentWithDate} isLocked={isLocked} />;
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Card sx={{ borderRadius: 6, boxShadow: '0px 2px 6px 2px rgba(51, 51, 51, 0.2)' }}>
        <CardContent sx={{ p: `0 !important` }}>
          <Box sx={{ my: 2, px: 2, maxWidth: '100%' }}>
            <Stack direction="row" alignItems="center">
              <Box sx={{ ml: 1, mr: 3 }}>
                <ContentCategoryBadge category={category} />
              </Box>

              <Box mr={2}>
                <CardMedia
                  component="img"
                  sx={{
                    borderRadius: '100%',
                    width: {
                      xs: '100px',
                      md: '120px',
                    },
                    minWidth: {
                      xs: '100px',
                      md: '120px',
                    },
                    height: { xs: '100px', md: '120px' },
                  }}
                  image={content.video.bannerUrl}
                />
              </Box>

              <Box
                mr={2}
                sx={{
                  width: {
                    sm: 'calc(100% - 200px)',
                    md: 'calc(100% - 414px)',
                    lg: 'calc(100% - 414px)',
                  },
                }}
              >
                <Box sx={{ display: 'flex', mb: 1 }}>
                  <Box mr={1}>
                    <Calender fontSize={25} color={theme.palette.primary.main} />
                  </Box>
                  <Typography variant="body2" sx={{ fontSize: '13px', lineHeight: 2 }}>
                    {formatDate(activeOn)}
                  </Typography>
                </Box>
                <Typography gutterBottom variant="body1" sx={{ ...ellipsis }}>
                  {content.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {content.instructor.firstName} {content.instructor.lastName}
                </Typography>
              </Box>

              <Stack>
                {content.type === 'VIDEO' && (
                  <Link
                    to={`/classes/details/$classId` as LinkProps['to']}
                    params={{
                      classId: content.id,
                    }}
                    {...linkProps}
                    style={{
                      textDecoration: 'none',
                      pointerEvents: disablePlayButton ? 'none' : 'auto',
                    }}
                  >
                    <Button
                      disabled={disablePlayButton}
                      sx={{
                        p: 0,
                        display: 'flex',
                        color: theme => theme.palette.common.white,
                        alignItems: 'center',
                        borderRadius: '30px',
                        background: theme =>
                          disablePlayButton
                            ? theme.palette.grey[300]
                            : `linear-gradient(95.28deg, ${theme.palette.primary.main} 36.31%, ${theme.palette.primary.light} 57.37%)`,
                      }}
                    >
                      <Box
                        sx={{
                          paddingLeft: '20px',
                          paddingRight: '2px',
                          lineHeight: '26px',
                        }}
                      >
                        Play Video
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '50px',
                          width: '50px',
                          borderRadius: '50%',
                          background: theme =>
                            disablePlayButton ? theme.palette.grey[400] : theme.palette.primary.main,
                          color: theme => theme.palette.common.white,
                          boxShadow: `5px 5px 15px ${theme.palette.common.black}20`,
                          marginLeft: '10px',
                        }}
                      >
                        <MvPlay />
                      </Box>
                    </Button>
                  </Link>
                )}

                {content.type === 'EVENT' && (
                  <Box
                    sx={{
                      marginRight: '25px',
                      display: 'flex',
                      color: theme => theme.palette.common.white,
                      alignItems: 'center',
                      transition: 'all 0.3s ease',
                      borderRadius: '50px',
                      '&:hover': {
                        background: `linear-gradient(93deg, ${theme.palette.primary.main} -0.51%, ${theme.palette.primary.light} 89.09%)`,
                        boxShadow: `5px 5px 15px ${theme.palette.common.black}20`,
                      },
                      '&:hover .textpart': {
                        width: '190px',
                        fontSize: '16px',
                      },
                      '&:hover .icon-bg': {
                        background: 'transparent',
                        boxShadow: 'none',
                      },
                    }}
                  >
                    <Box
                      className="textpart"
                      sx={{
                        paddingLeft: '20px',
                        paddingRight: '2px',
                        fontSize: 0,
                        lineHeight: '26px',
                        width: '180px',
                        transition: 'all 0.6s ease',
                      }}
                    >
                      <Typography variant="body2" sx={{ fontSize: '15px' }}>
                        Add to Google Calendar
                      </Typography>
                    </Box>
                    <Box
                      className="icon-bg"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '50px',
                        width: '50px',
                        borderRadius: '50%',
                        background: theme => theme.palette.primary.light,
                        color: theme => theme.palette.common.white,
                        boxShadow: `5px 5px 15px ${theme.palette.common.black}20`,
                        marginLeft: '10px',
                        p: '3px',
                      }}
                    >
                      <AddToCalender style={{ fontSize: '44px' }} />
                    </Box>
                  </Box>
                )}
              </Stack>
            </Stack>

            {content.isNew && (
              <NewBadge
                style={{
                  position: 'absolute',
                  fontSize: '70px',
                  right: -5,
                  top: 0,
                }}
              />
            )}
          </Box>

          <Box
            sx={{
              background: theme => darken(theme.palette.grey[100], 0.01),
              padding: '8px',
              border: theme => `1px solid ${theme.palette.grey[100]}`,
            }}
          >
            <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between', alignItems: 'center', ml: 1 }}>
              <Chip
                label={`${content.video.duration} m`}
                sx={{
                  mr: 1,
                  mt: 0.5,
                  mb: 0.5,
                  pl: 0.2,
                  pr: 1,
                  height: '28px',
                  backgroundColor: theme => theme.palette.grey[800],
                  color: 'white',
                }}
                size="small"
                icon={<Clock fontSize={18} color={theme.palette.common.white} />}
              />

              <Box sx={{ width: '100%', mb: 5 }}>
                {equipments.map(item => (
                  <Chip
                    key={item.slug}
                    label={item.name}
                    sx={{
                      mr: 1,
                      mt: 0.5,
                      mb: 0.5,
                      pl: 0.2,
                      pr: 1,
                      height: '28px',
                      backgroundColor: theme => theme.palette.grey[800],
                      color: 'white',
                    }}
                    size="small"
                  />
                ))}
              </Box>
              <Stack direction="row" justifyContent="space-between" width="100%">
                <RequirementIndicator
                  indicator={IndicatorEnum.difficulty}
                  point={averageRating.difficulty}
                  isInteractive={false}
                />

                <RequirementIndicator
                  indicator={IndicatorEnum.intensity}
                  point={averageRating.intensity}
                  isInteractive={false}
                />

                <RequirementIndicator
                  indicator={IndicatorEnum.nerdiness}
                  point={averageRating.nerdiness}
                  isInteractive={false}
                />
              </Stack>
            </Stack>
          </Box>
        </CardContent>
      </Card>

      <LockedCardOverlay isLocked={isLocked} />
    </Box>
    // <Box />
  );
};
