import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { SxProps, alpha, darken, useTheme } from '@mui/material';
import { BoxPlayButton, UpArrow } from '@onesource/icons';
import { TSeriesResponse } from '@onesource/schemas';
import { Link } from '@tanstack/react-router';
import { FC, useCallback, useState } from 'react';
import { SlArrowRight } from 'react-icons/sl';
import { ellipsis, trimText } from '@onesource/utils';

type TProps = {
  fitnessItem: TSeriesResponse;
  disableClick?: boolean;
  customize?: {
    actionButtonSx?: SxProps;
  };
};

export const FitnessSeries: FC<TProps> = props => {
  const { fitnessItem, disableClick = false, customize } = props;
  const theme = useTheme();
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);

  const fitnessItemName = fitnessItem.name;
  const bannerUrl = fitnessItem.imageUrl2x;
  const contentCount = fitnessItem.classCount;

  const handleMouseEnter = useCallback(() => {
    setIsDescriptionVisible(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsDescriptionVisible(false);
  }, []);

  const trimmedDescription = trimText(fitnessItem?.description ?? '', 193);

  return (
    <Card
      sx={{
        position: 'relative',
        borderRadius: '30px',
        boxShadow: 'none',
      }}
      onMouseLeave={handleMouseLeave} // Close when leaving the entire card
    >
      <CardMedia
        component="img"
        height="300"
        image={bannerUrl ?? ''}
        alt={fitnessItemName}
        sx={{
          position: 'relative',
          borderRadius: '30px 30px 0 0',
        }}
        onMouseEnter={handleMouseEnter}
      />

      <CardContent
        sx={{
          position: 'absolute',
          bottom: '53px',
          top: isDescriptionVisible ? '0' : { xs: '240px', md: '230px' },
          left: 0,
          right: 0,
          pt: 2,
          px: 3,
          pb: 3,
          zIndex: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          background: isDescriptionVisible
            ? alpha(theme.palette.grey[900], 0.8)
            : 'linear-gradient(180deg, rgba(50, 50, 50, 0.00) 0%, #333 100%)',
          transition: 'all 0.25s ease',
        }}
        onMouseEnter={handleMouseEnter} // Keep open when hovering over CardContent
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Tooltip title={fitnessItem.name}>
            <Typography
              variant="body1"
              sx={{
                ...ellipsis,
                color: theme => theme.palette.common.white,
                width: '100%',
                fontSize: { xs: '18px', sm: '20px', md: '25px', lg: '30px' },
                fontWeight: { xs: '650', md: '700' },
              }}
            >
              {fitnessItem.name}
            </Typography>
          </Tooltip>
          <UpArrow
            style={{
              color: theme.palette.common.white,
              fontSize: '17px',
              transform: isDescriptionVisible ? 'rotate(0deg)' : 'rotate(180deg)',
              transition: 'transform 0.25s ease',
              paddingTop: '13px',
              paddingBottom: '13px',
            }}
          />
        </Box>

        <Box sx={{ py: { xs: 1, md: 2 }, opacity: isDescriptionVisible ? 1 : 0, transition: 'all 0.3s ease' }}>
          <Typography
            variant="body2"
            sx={{
              color: theme => theme.palette.common.white,
              width: '100%',
              mb: 2,
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: trimmedDescription }} />
          </Typography>
          <Link
            {...(disableClick && {
              onClick: event => {
                event?.preventDefault();
              },
            })}
            to={`/series/${fitnessItem.id}`}
          >
            <Button
              endIcon={
                <Box
                  sx={{
                    background: theme.palette.primary.main,
                    borderRadius: '100%',
                    padding: '14px',
                    lineHeight: 0,
                  }}
                >
                  <SlArrowRight
                    style={{
                      color: theme.palette.common.white,
                      height: '17px',
                      width: '17px',
                    }}
                  />
                </Box>
              }
              sx={{
                marginBottom: 8,
                opacity: isDescriptionVisible ? 1 : 0,
                transition: 'all 0.9s ease',
                ...(customize?.actionButtonSx ?? {}),
              }}
            >
              Start the series
            </Button>
          </Link>
        </Box>
      </CardContent>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          background: theme => alpha(theme.palette.primary.light, 0.1),
          borderRadius: '0 0 30px 30px',
          padding: '15px 30px',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.05)',
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: darken(theme.palette.common.white, 0.799),
            display: 'flex',
            alignItems: 'center',
            fontSize: '13px',
            lineHeight: '2',
          }}
        >
          <BoxPlayButton style={{ fontSize: '26px', color: theme.palette.primary.main, marginRight: '14px' }} />
          {contentCount} classes
        </Typography>
      </Box>
    </Card>
  );
};
