import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type TProps = SwitchProps & {
  name: string;
  label: string;
};

export const RHFSwitch: FC<TProps> = props => {
  const { sx, name, label } = props;
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const { ref } = field;
        return (
          <>
            <FormControlLabel
              {...field}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                pr: 1,
                width: 'fit-content',
                borderRadius: 1,
                ...sx,
              }}
              control={<Switch ref={ref} color="primary" checked={field.value} />}
              label={label}
            />
            {!!error && <FormHelperText error={!!error}>{error && error?.message}</FormHelperText>}
          </>
        );
      }}
    />
  );
};
