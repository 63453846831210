import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import { useSearch } from '@tanstack/react-router';
import { PageHeader } from 'components/page-header';

function Viewer() {
  const queryParams: { url: string; title: string } = useSearch({ strict: false });

  return (
    <>
      <PageHeader title={queryParams.title || 'PDF Viewer'} />

      <Container sx={{ height: `calc(100vh - 230px)` }}>
        <Card sx={{ height: '100%', width: '100%' }}>
          <CardMedia component="iframe" height="100%" sx={{ border: 0 }} src={queryParams.url} />
        </Card>
      </Container>
    </>
  );
}

export default Viewer;
