import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { ChipProps } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { EventStatusEnum, TBaseEventSchema, TEventStatus } from '@onesource/schemas';
import { FC } from 'react';

import { ellipsis, formatDateWithTime, trimText } from '@onesource/utils';
import { useGetByDevice } from '../../hooks';

type TProps = {
  event: TBaseEventSchema;
  actions?: JSX.Element | JSX.Element[];
};

export const EventCardV2: FC<TProps> = ({ event, actions }) => {
  const { instructor } = event;
  const { isMobile, isXsMobile, isTablet } = useGetByDevice();
  const eventStatusChipColor: Record<TEventStatus, ChipProps['color']> = {
    [EventStatusEnum.SCHEDULED]: 'warning',
    [EventStatusEnum.STARTED]: 'success',
    [EventStatusEnum.ENDED]: 'error',
  };

  return (
    <Card
      sx={{
        borderRadius: 4,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        minHeight: '200px',
        boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      }}
    >
      <Grid container spacing={2}>
        <Grid md={4} xs={12} item>
          <CardMedia
            component="img"
            image={instructor.profilePicture?.landscape ?? ''}
            sx={{
              height: '100%',
              objectFit: 'cover',
            }}
            alt={`${instructor.firstName} ${instructor.lastName}`}
          />
        </Grid>

        <Grid md={8} item>
          <CardContent
            sx={{
              p: 3,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              boxSizing: 'border-box',
            }}
          >
            <Stack
              direction={isTablet ? 'column' : 'row'}
              justifyContent="space-between"
              spacing={{ xs: 1, md: 2 }}
              mb={isTablet ? 2 : 0}
            >
              <Typography
                variant={isMobile ? 'h6' : 'h5'}
                sx={{
                  mb: 1,
                  fontWeight: 'bold',
                  width: isXsMobile ? 100 : isMobile ? 170 : isTablet ? 300 : 'auto',
                  ...ellipsis,
                }}
              >
                {event.title}
              </Typography>

              <Stack spacing={{ xs: 1, md: 2 }} direction="row">
                <Chip
                  color="info"
                  variant="outlined"
                  label={event.type}
                  size={isMobile || isTablet ? 'small' : 'medium'}
                />
                <Chip
                  variant="filled"
                  color={eventStatusChipColor[event.status]}
                  label={event.status}
                  size={isMobile || isTablet ? 'small' : 'medium'}
                />
              </Stack>
            </Stack>

            <Stack direction="row">
              <Typography variant="body2" color="textSecondary" mb={0.5} fontStyle="italic">
                {formatDateWithTime(new Date(event.scheduleDateTime.toString()))}
              </Typography>
            </Stack>

            <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
              {event?.description && <span dangerouslySetInnerHTML={{ __html: trimText(event.description, 500) }} />}
            </Typography>

            <Box flex={1} />

            {actions ? actions : null}
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};
