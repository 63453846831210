import Grid from '@mui/material/Grid';
import { FeaturedClass } from '@onesource/components';
import { EAppNameEnum } from '@onesource/schemas';

import { useGetContentsForTodayQuery } from '@onesource/services';
import { FC } from 'react';

export const ContentForToday: FC = () => {
  const { data: classForToday } = useGetContentsForTodayQuery(EAppNameEnum.FIT);

  return (
    <Grid item xs={12}>
      {classForToday?.items?.[0] && <FeaturedClass scheduledContent={classForToday.items?.[0]} />}
    </Grid>
  );
};
