import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {
  AddEditPlaylistDialog,
  Content,
  DataStateHandler,
  PostFilterSidebar,
  PostLoading,
  useGetByDevice,
  useSetSearchParams,
} from '@onesource/components';
import { EAppNameEnum, TContent, contentFilterDefaultValues } from '@onesource/schemas';
import { useGetContentsQuery, useGetSubscriptionQuery, useGetUserWatchedContentIDsQuery } from '@onesource/services';
import { usePlaylistMenuService } from 'components/add-to-playlist/service';
import { usePlaylistAction } from 'hooks/use-playlist-actions';
import { FC, useCallback } from 'react';
import { ContentForToday } from '../../components/content-for-today';
import { useRouteUtils } from '../../components/use-route-utils';
import { ClassesHeader } from 'components/sections/classes-header';

export const AllClasses: FC = () => {
  const { searchParams } = useRouteUtils();
  const { isPortable } = useGetByDevice();

  const {
    contextClassId,
    onAddToPlaylist,
    createPlaylist,
    isCreatePending,
    onClickCreate,
    onClosePlaylistDialog,
    isCreatePlaylistDialogOpen,
  } = usePlaylistAction();

  const searchFilterUtils = useSetSearchParams({
    initialFilters: { ...contentFilterDefaultValues, ...searchParams, scrollTop: '1' },
    resetFilters: { ...contentFilterDefaultValues, scrollTop: '1' },
  });
  const { data: watchedContents } = useGetUserWatchedContentIDsQuery();

  const contentStates = useGetContentsQuery({
    app: EAppNameEnum.FIT,
    filters: {
      ...searchFilterUtils.filters,
      shuffle: true,
    },
  });
  const { isFitSubscriptionActive } = useGetSubscriptionQuery();

  const { patchFilter } = searchFilterUtils;

  const onNext = useCallback((page: number) => {
    patchFilter({ page: page.toString() });
  }, []);

  const onPrev = useCallback((page: number) => {
    patchFilter({ page: page.toString() });
  }, []);

  return (
    <>
      <ClassesHeader searchFilterUtils={searchFilterUtils} />

      <Box sx={{ py: { xs: 3, md: 7 }, background: theme => theme.palette.common.white, overflow: 'hidden' }}>
        <Container maxWidth="xl">
          <Grid container spacing={4}>
            <Grid item md={3} sm={6} xs={12} sx={{ display: isPortable ? 'none' : 'block' }}>
              <PostFilterSidebar searchFilterUtils={searchFilterUtils} />
            </Grid>

            <Grid item md={12} lg={9} sm={12} xs={12}>
              <DataStateHandler
                queryResult={contentStates}
                onNext={onNext}
                onPrev={onPrev}
                entity="Classes"
                loadingProps={{
                  loadingWrapperProps: {
                    flexDirection: 'row',
                  },
                  loadingItemProps: {
                    sm: 4,
                    md: 4,
                    xs: 12,
                  },
                }}
                gridProps={{ md: 4, sm: 6, xs: 12 }}
                pagination={!!contentStates.data?.meta.totalPages}
                LoadingComponent={PostLoading}
                IterativeComponent={(props: { item: TContent }) => {
                  // INFO: we need to fix this
                  const { setPlaylistMenu } = usePlaylistMenuService();

                  const onClickAddPlaylistHandler = useCallback(
                    (anchorEl: HTMLElement, contentId: string) => {
                      setPlaylistMenu({
                        contentId,
                        anchorEl,
                        onAddToPlaylist,
                        onClickCreate,
                      });
                    },
                    [onAddToPlaylist, onClickCreate, onClosePlaylistDialog],
                  );
                  return (
                    <Content
                      hasWatched={!!watchedContents?.has(props.item.id)}
                      linkProps={{
                        search: {
                          category: props.item.category,
                        },
                      }}
                      content={props.item}
                      isLocked={!isFitSubscriptionActive}
                      onClickAddPlaylist={onClickAddPlaylistHandler}
                    />
                  );
                }}
              />
            </Grid>
            <ContentForToday />
          </Grid>
        </Container>

        <AddEditPlaylistDialog
          isPending={isCreatePending}
          open={isCreatePlaylistDialogOpen}
          onClose={onClosePlaylistDialog}
          onSave={createPlaylist}
          contentId={contextClassId}
        />
      </Box>
    </>
  );
};
