import { IPaginationNew, TError, TPlaylist } from '@onesource/schemas';
import { queryOptions, useQuery } from '@tanstack/react-query';
import axios from '../axios';
import { ENTITY } from './entity';

type TParams = {
  enabled?: boolean;
};

export const getAllPlaylistsQueryKey = () => {
  return [ENTITY];
};

export const getAllPlaylistQueryOptions = (params?: TParams) => {
  const { enabled = true } = params ?? {};
  return queryOptions<IPaginationNew<TPlaylist>, TError>({
    queryKey: getAllPlaylistsQueryKey(),
    queryFn: async () => {
      const res = await axios.get(ENTITY);
      return res.data;
    },
    enabled,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
};
export type TGetAllPlaylistQueryOptionsReturn = ReturnType<typeof getAllPlaylistQueryOptions>;

export const useGetAllPlaylistsQuery = (params?: TParams) => {
  return useQuery(getAllPlaylistQueryOptions(params));
};
