import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Select, { SelectProps } from '@mui/material/Select';
import { FC, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import ListSubheader from '@mui/material/ListSubheader';

export type TMultiselectItem<T = unknown> = {
  id: string | number;
  name: string;
  value: string | number | T;
};

type TProps = SelectProps & {
  name: string;
  native?: boolean;
  helperText?: React.ReactNode;
  children: ReactNode | ReactNode[];
};

export const RHFSelect: FC<TProps> = props => {
  const { name, label, helperText, children, native, ...others } = props;

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <FormControl fullWidth>
            <InputLabel id={`label-${name}`}>{label}</InputLabel>
            <Select
              {...field}
              name={name}
              labelId={`label-${name}`}
              label={label}
              native={native}
              fullWidth
              {...others}
            >
              <ListSubheader>Select an Option</ListSubheader>
              {children}
            </Select>
            {(!!error || helperText) && (
              <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
};
