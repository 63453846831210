import { ThemeProvider } from '@mui/material';
import {
  AuthProvider,
  ConfirmProvider,
  ContextMenuProvider,
  OneSourceSnackbarProvider,
  ProfileInfoProvider,
} from '@onesource/components';
import { theme } from '@onesource/theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import { routeTree } from './routeTree.gen';
import { GTM_ID, SHOW_DEV_TOOLS } from 'config';

const router = createRouter({ routeTree });

/* WARNING: Do not remove this code. it will mess up the route types */
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

router.subscribe('onLoad', (params: { toLocation: { search: { scrollTop?: string } } }) => {
  if (params.toLocation.search.scrollTop !== '1') {
    window.scrollTo(0, 0);
  }
});

router.subscribe('onBeforeLoad', () => {
  if (!GTM_ID) {
    return;
  }
  const url = router.history.location.href;

  const head = document.querySelector('head');
  const body = document.querySelector('body');

  const isScriptFound = !!head?.querySelector(`script[data-gtm="itbz-${url}"]`);
  const isNoScriptFound = !!body?.querySelector(`noscript[data-gtm="itbz-${url}"]`);

  if (!isScriptFound) {
    const script = document.createElement('script');
    script.setAttribute('data-gtm', `itbz-${url}`);
    script.innerText = `
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', '${GTM_ID}');
     `;
    head?.appendChild(script);
  }

  if (!isNoScriptFound) {
    const noscript = document.createElement('noscript');
    noscript.setAttribute('data-gtm', `itbz-${url}`);
    const iframe = document.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${GTM_ID}`;
    iframe.height = '0';
    iframe.width = '0';
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';
    noscript.appendChild(iframe);
    body?.appendChild(noscript);
  }
});

export const queryClient = new QueryClient();

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ContextMenuProvider>
            <ConfirmProvider>
              <ProfileInfoProvider>
                <OneSourceSnackbarProvider>
                  <RouterProvider router={router} />
                </OneSourceSnackbarProvider>
              </ProfileInfoProvider>
            </ConfirmProvider>
          </ContextMenuProvider>
        </AuthProvider>
        {SHOW_DEV_TOOLS && <ReactQueryDevtools />}
      </QueryClientProvider>
    </ThemeProvider>
  );
};
