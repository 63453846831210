import { TError, TRequestSchema, IPaginationNew, TSeriesResponse, PlaylistsResponseSchema } from '@onesource/schemas';
import { useQuery } from '@tanstack/react-query';
import axios from '../axios';
import { ENTITY } from './entity';

interface IGetSeriesParams {
  filters?: TRequestSchema & {
    loadAll?: boolean;
  };
  enabled?: boolean;
}

export const getAllSeriesQueryKey = (filters?: TRequestSchema) => {
  const serializeFilters = Object.entries(filters || {});
  return [ENTITY, serializeFilters];
};

export const useGetAllSeriesQuery = (param: IGetSeriesParams = {}) => {
  const { filters, enabled = true } = param;

  return useQuery<IPaginationNew<TSeriesResponse>, TError>({
    queryKey: getAllSeriesQueryKey(filters),
    queryFn: async () => {
      const res = await axios.get<IPaginationNew<TSeriesResponse>>(ENTITY, {
        params: {
          filters,
        },
      });

      const data = PlaylistsResponseSchema.safeParse(res.data);
      if (!data.success) {
        console.error('unable to parse response');
        return res.data;
      }

      return data.data;
    },
    enabled,
    refetchOnWindowFocus: false,
  });
};
