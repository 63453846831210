import { SxProps, Theme, useTheme } from '@mui/material';
import { IoPerson } from 'react-icons/io5';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { TInstructor } from '@onesource/schemas';
import { FC, useMemo } from 'react';
import { useBoolean } from 'usehooks-ts';
import { Link } from '@tanstack/react-router';
import { ellipsis } from '@onesource/utils';
import { useGetByDevice } from '../../hooks';

interface TeacherCardProps {
  instructor: TInstructor;
  onClick?: () => void;
  type: 'SLIDER' | 'V-CARD' | 'H-CARD';
  sx?: SxProps;
  selected?: boolean;
}

export const InstructorCard: FC<TeacherCardProps> = props => {
  const { instructor, onClick, selected = null, sx, type } = props;
  const theme = useTheme();
  const { setTrue, setFalse, value: isHover } = useBoolean(false);
  const { isMobile } = useGetByDevice();

  const isSlider = type === 'SLIDER';
  const isVCard = type === 'V-CARD';
  const isHCard = type === 'H-CARD';
  const fullName = `${instructor.firstName} ${instructor.lastName}`;

  const avatarSize = useMemo(() => {
    if (isHCard) {
      return isMobile ? '110px' : '200px';
    }
    if (isVCard) {
      return isMobile ? '150px' : '300px';
    }
    if (isSlider) {
      return '180px';
    }
  }, [isHCard, isVCard, isSlider]);

  const avatarStyle: SxProps<Theme> = {
    overflow: 'hidden',
    boxSizing: 'border-box',
    opacity: selected || selected === null ? 1 : 0.4,
    width: avatarSize,
    height: avatarSize,
    background: `linear-gradient(180deg, rgba(250,231,233,1) 0%, rgba(226,150,160,1) 65%, rgba(192,37,60,1) 100%)`,
  };

  return (
    <Link
      disabled={typeof onClick === 'function'}
      style={{ textDecoration: 'none', cursor: 'pointer' }}
      to={isHCard ? `/classes/all?instructorIds="${instructor.id}"` : `/instructors/${instructor.id}`}
    >
      <Card
        onMouseEnter={setTrue}
        onMouseLeave={setFalse}
        sx={{
          px: { xs: 0, md: 2.5 },
          boxShadow: 'none',
          background: 'none',
          cursor: typeof onClick === 'function' ? 'pointer' : 'default',
          ...sx,
        }}
        onClick={onClick}
      >
        <CardContent
          sx={{
            textAlign: 'center',
            margin: 'auto',
            alignItems: 'center',
            display: 'flex',
            p: 0,
            flexDirection: isHCard ? 'row' : 'column',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              '&:before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '100%',
                background: theme => theme.palette.primary.main,
                opacity: isHover ? 0.2 : 0,
                zIndex: 99,
                transition: 'opacity 0.2s ease',
              },
            }}
          >
            {instructor.profilePicture?.square ? (
              <Avatar alt={fullName} src={instructor.profilePicture?.square} sx={avatarStyle} />
            ) : (
              <Avatar alt={fullName} sx={avatarStyle}>
                <Box
                  sx={{
                    pt: 5,
                    overflow: 'hidden',
                    maxHeight: avatarSize,
                  }}
                >
                  <IoPerson size={avatarSize} />
                </Box>
              </Avatar>
            )}
          </Box>

          <Box
            sx={{
              ml: isHCard ? 3 : 0,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                mt: { xs: 1, md: 2 },
                color: isHover ? theme.palette.primary.main : theme.palette.grey[800],
                fontWeight: 600,
                transition: 'color 0.2s ease',
                ...(!isSlider && {
                  fontWeight: '700',
                }),
                ...(!isHCard ? ellipsis : { textAlign: 'left', lineHeight: 1.2 }),
              }}
            >
              {fullName}
            </Typography>

            {instructor.role && (
              <Typography
                variant="body2"
                sx={{
                  mt: 1,
                  display: isHCard ? 'none' : 'block',
                  textAlign: isHCard ? 'left' : 'center',
                  transition: 'color 0.2s ease',
                  color: isHover ? theme.palette.primary.main : theme.palette.grey[800],
                }}
              >
                <span dangerouslySetInnerHTML={{ __html: instructor.role }} />
              </Typography>
            )}

            <Typography
              variant="body2"
              sx={{
                mt: 1,
                textAlign: isHCard ? 'left' : 'center',
                transition: 'color 0.2s ease',
                color: isHover ? theme.palette.primary.main : theme.palette.grey[800],
              }}
            >
              {instructor.location}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Link>
  );
};
