import { TAppNameEnum, TContent, TError, TYouMayLikeRequestFilter } from '@onesource/schemas';
import { useQuery } from '@tanstack/react-query';
import axios from '../axios';
import { ENTITY } from './entity';
type TUseGetYouMayLikeParams = {
  filters: TYouMayLikeRequestFilter;
  enabled?: boolean;
  app: TAppNameEnum;
};

export const getYouMayLikeQueryKey = (params: TUseGetYouMayLikeParams['filters']) => [
  'related-posts',
  Object.entries(params),
];

export const useGetRelatedPostsQuery = (params: TUseGetYouMayLikeParams) => {
  const { filters, enabled = true, app } = params;
  return useQuery<TContent[], TError>({
    queryKey: getYouMayLikeQueryKey(params.filters),
    queryFn: async () => {
      const res = await axios.get<TContent[]>(`${ENTITY}/related-posts`, { params: { ...filters, app } });
      return res.data;
    },
    enabled,
    refetchOnWindowFocus: false,
  });
};
