import { AddToPlaylistMenu } from '@onesource/components';
import { FC, ReactElement } from 'react';
import { usePlaylistMenuService } from '../service';
import { useGetAllPlaylistsQuery } from '@onesource/services';

export interface IContextMenuProps {
  children: ReactElement;
}

export const PlaylistMenuProvider: FC<IContextMenuProps> = props => {
  const { children } = props;
  const { contextMenuConfig, closePlaylistMenu } = usePlaylistMenuService();
  const { data: playlistRes, isLoading: isAddToPlaylistPending } = useGetAllPlaylistsQuery({
    enabled: !!contextMenuConfig?.contentId && !!contextMenuConfig?.anchorEl,
  });
  const { contentId, anchorEl, onAddToPlaylist, onClickCreate } = contextMenuConfig || {};

  return (
    <>
      {children}

      {
        <AddToPlaylistMenu
          playlists={playlistRes?.items || []}
          contentId={contentId || null}
          anchorEl={anchorEl || null}
          isLoading={isAddToPlaylistPending}
          onAddToPlaylist={onAddToPlaylist}
          onClickCreate={onClickCreate}
          handleClose={closePlaylistMenu}
        />
      }
    </>
  );
};
