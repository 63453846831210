import Box from '@mui/material/Box';
import { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { Link } from '@tanstack/react-router';
import MuiLink from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { RycLogo } from '@onesource/components';

const footerLinks = [
  { label: 'PRIVACY POLICY', link: 'https://laurenohayon.com/privacy-policy/' },
  { label: 'MEDICAL DISCLAIMER', link: 'https://laurenohayon.com/medical-disclaimer/' },
  { label: 'CONTACT', link: 'mailto:support@restoreyourcore.com' },
];

export const Footer: FC = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: theme.palette.grey[900],
        pt: 5,
        pb: 8,
      }}
    >
      <Container maxWidth="xl">
        <Stack
          alignItems="center"
          justifyContent="space-between"
          sx={{
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            gap: {
              xs: 2,
              // md: 5
            },
          }}
        >
          <RycLogo fill={theme.palette.common.white} sx={{ '& svg': { width: '124px', height: '80px' } }} />

          <Box
            component="ul"
            sx={{
              display: 'flex',
              listStyle: 'none',
              flexDirection: { xs: 'column', md: 'row' },
              gap: { xs: 2 },
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            {footerLinks.map(({ label, link }) => (
              <Box
                component="li"
                key={label}
                sx={{
                  ml: { xs: 0, md: 4 },
                  '& a': {
                    color: theme.palette.common.white,
                    textDecoration: 'none',
                    transition: 'all 0.15s ease-in-out',
                    '&:hover': {
                      color: theme.palette.primary.main,
                    },
                  },
                }}
              >
                <Link target="_blank" to={link}>
                  {label}
                </Link>
              </Box>
            ))}
          </Box>
        </Stack>

        <Divider sx={{ borderColor: 'white', my: 3, height: '1px' }} />

        <Typography align="center" color="common.white" fontWeight="300" mb={1}>
          For any questions email:{' '}
          <MuiLink href="mailto:support@restoreyourcore.com">support@restoreyourcore.com</MuiLink>
        </Typography>

        <Typography align="center" color="common.white" fontWeight="300">
          © 2024 RYC<sup>®</sup>. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};
