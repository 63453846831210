import { FC, useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import { Link } from '@tanstack/react-router';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { darken, useTheme } from '@mui/material';
import { PostFilterSidebar, TSidebarItem, TUseSetSearchParam, useGetByDevice } from '@onesource/components';
import { ImEqualizer, ImEqualizer2 } from 'react-icons/im';

const navItem = [
  { link: '/classes/by-date', label: 'By Date' },
  { link: '/classes/by-category', label: 'By Category' },
  { link: '/classes/by-teachers', label: 'By Instructor' },
  { link: '/classes/all', label: 'All Classes' },
];

interface IProps {
  filterPathname: string;
  isByDate: boolean;
  searchFilterUtils?: TUseSetSearchParam;
}

export const ClassesHeaderFilter: FC<IProps> = props => {
  const { filterPathname, isByDate, searchFilterUtils } = props;
  const theme = useTheme();
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const { isPortable } = useGetByDevice();
  const isShowPostFilter = isPortable && searchFilterUtils && !isByDate;

  const handleToggleFilter = useCallback(() => {
    setIsFilterVisible(!isFilterVisible);
  }, [isFilterVisible]);

  const handleHideFilter = (): TSidebarItem[] => {
    if (filterPathname === 'by-category') {
      return ['category'];
    } else if (filterPathname === 'by-teachers') {
      return ['instructor'];
    }
    return [];
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          sx={{
            py: { xs: 2, md: 4 },
            justifyContent: 'center',
            display: 'flex',
            '& a': {
              color: 'rgba(51, 51, 51, 0.50)',
              px: { xs: 0.5, md: 2.5 },
              fontSize: { xs: 13, sm: 14.5, md: 18 },
              fontWeight: 500,
              textDecoration: 'none',
              transition: 'all 0.3s ease-in-out',
            },
            '& a:after': {
              marginLeft: { xs: 0.6, md: 3 },
              content: '"|"',
              display: 'inline-block',
            },
            '& a:last-child:after': {
              display: 'none',
            },
            '& a.active': {
              color: theme.palette.primary.main,
              fontWeight: 'bold',
            },
            '& a:hover': {
              color: theme.palette.primary.main,
            },
          }}
        >
          {navItem.map(item => (
            <Link key={item.link} to={item.link}>
              {item.label}
            </Link>
          ))}
        </Box>
      </Grid>

      {isShowPostFilter && (
        <>
          <Grid
            item
            xs={12}
            sx={{
              px: 2,
              pb: 2,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: theme => darken(theme.palette.common.white, 0.799),
                fontWeight: 600,
                fontSize: '13px !important',
              }}
            >
              Use the filters or search by class title or keywords to find the perfect class!
            </Typography>

            <Box
              sx={{
                p: 0.5,
                cursor: 'pointer',
              }}
              onClick={handleToggleFilter}
            >
              {isFilterVisible ? (
                <ImEqualizer2 style={{ color: theme.palette.primary.main }} size={22} />
              ) : (
                <ImEqualizer style={{ color: theme.palette.primary.main }} size={22} />
              )}
            </Box>
          </Grid>

          <Grid item sx={{ display: isFilterVisible ? 'block' : 'none' }}>
            <PostFilterSidebar searchFilterUtils={searchFilterUtils} hide={handleHideFilter()} />
          </Grid>
        </>
      )}
    </Grid>
  );
};
