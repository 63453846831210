import { IPaginationNew, TAppNameEnum, TContent, TError } from '@onesource/schemas';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';
import { ENTITY } from './entity';
import { useGetSubscriptionQuery } from '../profile';
import { useMemo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TFilter = Record<string, any>;

interface IGetContentsParams {
  filters?: TFilter;
  enabled?: boolean;
  loadAll?: boolean;
  app: TAppNameEnum;
}

export const getContentsQueryKey = (filters: TFilter = {}, loadAll: boolean = false) => {
  const serializeFilters = Object.entries(filters || {});
  const keys = [ENTITY, serializeFilters];
  if (loadAll) {
    keys.push('loadAll');
  }
  return keys;
};

export const useGetContentsQuery = (param: IGetContentsParams) => {
  const { isLoadingProfileInfo } = useGetSubscriptionQuery();
  const { filters, enabled = true, loadAll, app } = param;

  const isDisabled = useMemo(() => [!enabled, isLoadingProfileInfo].some(Boolean), [enabled, isLoadingProfileInfo]);

  const [durationFrom, durationTo] = filters?.duration?.length ? filters?.duration?.split('-') : [];
  const [difficultyFrom, difficultyTo] = filters?.difficulty?.length ? filters?.difficulty.split('-') : [];
  const [intensityFrom, intensityTo] = filters?.intensity?.length ? filters?.intensity.split('-') : [];
  const [nerdinessFrom, nerdinessTo] = filters?.nerdiness?.length ? filters?.nerdiness.split('-') : [];
  const equipments = filters?.equipments?.split(',') || [];
  const instructorIds = filters?.instructorIds?.split(',') || [];
  const dashboard = filters?.dashboard;
  const shuffle = filters?.shuffle;

  return useQuery<IPaginationNew<TContent>, TError>({
    queryKey: getContentsQueryKey(filters, loadAll),
    queryFn: async () => {
      const res = await axios.get<IPaginationNew<TContent>>(ENTITY, {
        params: {
          ...filters,
          app,
          instructorIds,
          equipments,
          scrollTop: undefined,
          durationFrom,
          durationTo,
          difficultyFrom,
          difficultyTo,
          intensityFrom,
          intensityTo,
          nerdinessFrom,
          nerdinessTo,
          dashboard,
          shuffle,
        },
      });

      return res.data;
    },
    enabled: !isDisabled,
    refetchOnWindowFocus: false,
  });
};

export const useGetContentsQueryCachedData = (filters: TFilter = {}, loadAll: boolean = false) => {
  const qc = useQueryClient();
  const key = getContentsQueryKey(filters, loadAll);
  return qc.getQueryData<IPaginationNew<TContent>>(key);
};
