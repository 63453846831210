import { useQuery } from '@tanstack/react-query';
import { ENTITY } from './entity';
import axios, { setTokenToAxios } from '../axios';
import { TProfile } from '@onesource/schemas';

export const getUsersQueryKey = () => {
  return [ENTITY];
};

export const queryFn = async () => {
  const { data } = await axios.get<TProfile>(ENTITY);
  return data;
};

type TParams = {
  token?: string | null;
};

export const useGetAllProfileQuery = (params?: TParams) => {
  const token = params?.token;
  const isEnabled = !!token?.length;

  if (token) {
    setTokenToAxios(token);
  }

  return useQuery<TProfile>({
    queryKey: getUsersQueryKey(),
    queryFn,
    enabled: isEnabled,
  });
};

export type TUseGetProfileQueryResult = ReturnType<typeof useGetAllProfileQuery>;
