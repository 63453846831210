import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { TContent } from '@onesource/schemas';
import { useGetUserWatchedContentsQuery } from '@onesource/services';
import { FC, ReactElement } from 'react';
import { Content, PostLoading } from '../cards';
import { Slideshow } from './custom-slideshow';

export type TMostViewed = {
  sectionTitle: string;
  isLoading?: boolean;
  slides: TContent[];
  isSubscriptionActive?: boolean;
  filterNavElement?: ReactElement;
  onClickAddPlaylistHandler: (anchorEl: HTMLElement, contentId: string) => void;
};

export const CommonSlideshow: FC<TMostViewed> = props => {
  const { sectionTitle, slides, onClickAddPlaylistHandler, filterNavElement, isLoading, isSubscriptionActive } = props;

  const { data: watchedContents } = useGetUserWatchedContentsQuery();

  const SliderSettings = {
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    infinite: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: false,
          infinite: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '15%',
          infinite: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '10%',
          infinite: false,
        },
      },
    ],
  };

  return (
    <Container maxWidth="xl">
      <Grid container spacing={4} sx={{ py: { xs: 3, md: 6 } }}>
        <Grid item xs={12} mb={4}>
          <Typography
            variant="h2"
            sx={{
              mb: 2,
              textAlign: 'center',
            }}
          >
            {sectionTitle}
          </Typography>
          {!!filterNavElement && (
            <Box
              sx={{
                mb: 2,
                textAlign: 'center',
                '& a:after': {
                  marginLeft: '8px',
                  content: '"|"',
                  display: 'inline-block',
                  fontSize: '16px',
                  LineHeight: '20px',
                },
                '& a:last-child:after': {
                  display: 'none',
                },
              }}
            >
              {filterNavElement}
            </Box>
          )}
        </Grid>
        {!isLoading ? (
          <Grid item xs={12} pb={5}>
            <Grid
              container
              spacing={5}
              sx={{
                '& .slick-slider': {
                  width: { xs: '100%', md: 'calc(100% - 70px)' },
                  margin: 'auto',
                },
                '& .slick-track': {
                  transform: { xs: 'inherit', md: 'translated(0px, 0px, 0px) !important' },
                  display: 'flex',
                  gap: { xs: 2, sm: 4, md: 5 },
                  alignItems: 'center',
                  py: 1,
                },
              }}
              className="slider"
            >
              {slides.length <= 4 ? (
                slides.map(content => (
                  <Grid key={content.id} item xs={12} sm={6} md={4} lg={3}>
                    <Content
                      hasWatched={watchedContents?.has(content.id)}
                      onClickAddPlaylist={onClickAddPlaylistHandler}
                      isLocked={!isSubscriptionActive}
                      content={content}
                      key={content.id}
                    />
                  </Grid>
                ))
              ) : (
                <Slideshow
                  slides={slides}
                  renderSlide={content => (
                    <Content
                      hasWatched={watchedContents?.has(content.id)}
                      onClickAddPlaylist={onClickAddPlaylistHandler}
                      isLocked={!isSubscriptionActive}
                      content={content}
                      key={content.id}
                    />
                  )}
                  settings={SliderSettings}
                />
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={4} mt={0} sx={{ width: { xs: '100%', md: 'calc(100% - 70px)' }, margin: 'auto' }}>
            {Array.from({ length: 4 }).map((_, index) => (
              <Grid xs={12} sm={4} md={3} item key={index}>
                <PostLoading />
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
