import TextField, { TextFieldProps } from '@mui/material/TextField';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type TProps = TextFieldProps & {
  name: string;
};

export const RHFTextField: FC<TProps> = props => {
  const { name, helperText, ...others } = props;

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...others}
          {...field}
          value={field.value || ''}
          name={name}
          variant="outlined"
          error={!!error}
          helperText={error ? error.message : helperText}
          fullWidth
        />
      )}
    />
  );
};
