import { TInstructor, TError } from '@onesource/schemas';
import { useQuery } from '@tanstack/react-query';
import axios from '../axios';
import { ENTITY } from './entity';

interface IGetInstructorParams {
  instructorId: string;
}

export const getInstructorQueryKey = (instructorId: string) => {
  return [ENTITY, instructorId];
};

export const useGetInstructorQuery = (param: IGetInstructorParams) => {
  const { instructorId } = param;

  return useQuery<TInstructor, TError>({
    queryKey: getInstructorQueryKey(instructorId),
    queryFn: async () => {
      const res = await axios.get<TInstructor>(`${ENTITY}/${instructorId}`);
      return res.data;
    },
    refetchOnWindowFocus: false,
  });
};
