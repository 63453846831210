import { useState, useEffect, useCallback, createElement } from 'react';
import { WS_LIVE_ENDPOINT } from './../config';
import { Socket, io } from 'socket.io-client';
import { getSession, useGetAllProfileQuery } from '@onesource/services';
import { SocketEventTypeEnum, TEvent, TEventMessageSchema, TPubSubEvents } from '@onesource/schemas';
import { TParticipant } from '../components/participants/participants.type';
import { enqueueSnackbar } from 'notistack';
import { SnackbarCloseAction } from 'components';

export const useSocketConnection = (eventId: string) => {
  const token = getSession();

  const [connection, setSocket] = useState<Socket>();
  const [isConnected, setIsConnected] = useState(false);
  const [isError] = useState(false);
  const [participants, setParticipants] = useState<TParticipant[]>([]);
  const [eventInfo, setEventInfo] = useState<TEvent>();
  const [messages, setMessages] = useState<TEventMessageSchema[]>([]);
  const { data: userInfo } = useGetAllProfileQuery();

  const onConnect = useCallback(() => {
    console.log('Connected to live class socket');
    setIsConnected(true);
  }, []);

  const onDisconnect = useCallback(() => {
    setIsConnected(false);
  }, []);

  function onUserJoined(value: { users: TParticipant[]; messages: TEventMessageSchema[] }) {
    const { users, messages } = value;
    if (messages) {
      setMessages(() => messages);
    }

    if (users) {
      setParticipants(() => users);
    }
  }

  const onUserLeft = useCallback((user: TParticipant) => {
    setParticipants(prev => prev.filter(item => item.id !== user.id));
  }, []);

  function onEventInfo(event: TEvent) {
    setEventInfo(() => {
      return event;
    });
  }

  const onSendMessage = useCallback(
    (message: TEventMessageSchema) => {
      setMessages(prev => [message, ...prev]);

      if (userInfo?.id === parseInt(message.user.id)) {
        return;
      }

      enqueueSnackbar(`New message from ${message.user.username}`, {
        variant: 'info',
        autoHideDuration: 3000,
        preventDuplicate: true,
        action(snackbarId) {
          return createElement(SnackbarCloseAction, { snackbarId });
        },
        hideIconVariant: true,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    },
    [enqueueSnackbar, userInfo],
  );

  const emmitMessage = useCallback(
    <T extends {}>(params: { message: TPubSubEvents; payload: T }) => {
      if (!connection) {
        return;
      }
      const { message, payload } = params;

      connection.emit(message, payload);
    },
    [connection],
  );

  useEffect(() => {
    const socketConnection = io(WS_LIVE_ENDPOINT, {
      query: { token, eventId },
      transports: ['websocket'],
    });
    setSocket(() => socketConnection);

    socketConnection.on(SocketEventTypeEnum.connect, onConnect);
    socketConnection.on(SocketEventTypeEnum.USER_JOINED, onUserJoined);
    socketConnection.on(SocketEventTypeEnum.USER_LEFT, onUserLeft);
    socketConnection.on(SocketEventTypeEnum.EVENT_INFO, onEventInfo);
    socketConnection.on(SocketEventTypeEnum.EVENT_STARTED, onEventInfo);
    socketConnection.on(SocketEventTypeEnum.EVENT_FINISHED, onEventInfo);
    socketConnection.on(SocketEventTypeEnum.SEND_MESSAGE, onSendMessage);
    // socketConnection.on('connect_failed',);
    return () => {
      console.log('disconnecting');
      socketConnection.off(SocketEventTypeEnum.disconnect, onDisconnect);
      socketConnection.off(SocketEventTypeEnum.USER_JOINED, onUserJoined);
      socketConnection.disconnect();
      setParticipants([]);
    };
  }, []);

  return {
    isConnected,
    participants: participants.sort((a: TParticipant, b: TParticipant) => a.username.localeCompare(b.username)),
    isError,
    eventInfo,
    messages,
    connection,
    emmitMessage,
  };
};
