import { useMediaQuery, useTheme } from '@mui/material';
import { TSlideBadgeImagePositionEnum } from '@onesource/schemas';
import { useMemo } from 'react';

type TParams = {
  badgeImagePosition?: TSlideBadgeImagePositionEnum | null;
  bgImg?: string | null;
  badgeImage?: string | null;
};

export const useSlideBadgeImage = (params: TParams) => {
  const { badgeImagePosition, bgImg, badgeImage } = params;
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isMd = useMediaQuery(theme.breakpoints.up('sm'));

  const { imgPosition, badgePosition } = useMemo(() => {
    let imgPosition: 'center' | 'right' | 'left' = 'center';
    type TBadgePositionValue = 'auto' | `${number}%` | `${number}px`;
    const badgePosition: Partial<Record<'top' | 'bottom' | 'right' | 'left', TBadgePositionValue>> = {};

    if (badgeImagePosition === 'top-right' || badgeImagePosition === 'bottom-right') {
      imgPosition = 'right';

      badgePosition.right = '5%';

      badgePosition[badgeImagePosition === 'top-right' ? 'top' : 'bottom'] = '5%';

      return { imgPosition, badgePosition };
    }

    if (badgeImagePosition === 'top-left' || badgeImagePosition === 'bottom-left') {
      imgPosition = 'right';

      badgePosition.left = '5%';

      badgePosition[badgeImagePosition === 'top-left' ? 'top' : 'bottom'] = '5%';

      return { imgPosition, badgePosition };
    }

    return {
      imgPosition,
      badgePosition: {
        top: `calc(50% - ${isMd ? '200px' : '150px'})`,
        left: `calc(50% - ${isMd ? '200px' : '100px'})`,
      },
    };
  }, [badgeImagePosition, isMd]);

  const containerAfterCSS = useMemo(() => {
    if (bgImg && badgeImage && imgPosition === 'right') {
      return {
        '&:after': {
          content: "''",
          position: 'absolute',
          width: {
            xs: '100%',
            md: '1000px',
          },
          height: {
            xs: '100%',
            md: '100%',
          },
          top: '0px',
          right: { xs: '0px', md: '0px' },
          background: `linear-gradient(to right, transparent ,${theme.palette.common.black})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        },
      };
    }

    return {};
  }, [bgImg, badgeImage, imgPosition, theme]);

  const BadgeImage = () => {
    const width = useMemo(() => {
      if (isSm) {
        return '250px';
      }

      if (isMd) {
        return '400px';
      }

      return '400px';
    }, [isSm, isMd]);

    if (!badgeImage) {
      return null;
    }

    return (
      <img
        src={badgeImage}
        loading="lazy"
        alt="badge"
        style={{
          width,
          aspectRatio: '1/1',
          position: 'absolute',
          zIndex: 1,
          ...badgePosition,
        }}
      />
    );
  };

  return { containerAfterCSS, BadgeImage };
};
