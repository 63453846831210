import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { alpha } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { Link } from '@tanstack/react-router';
import { ellipsis } from '@onesource/utils';
import { TInstructor } from '@onesource/schemas';

export type TFeaturedInstructorCardProps = {
  instructor: TInstructor;
};

export const FeaturedInstructorCard: FC<TFeaturedInstructorCardProps> = props => {
  const { instructor } = props;

  const fullName = `${instructor.firstName} ${instructor.lastName}`;
  const profilePicture = instructor.profilePicture?.potrait ?? '';
  const role = instructor.role;

  return (
    <Card
      component={Link}
      to={`/instructors/${instructor.id}`}
      sx={{
        position: 'relative',
        borderRadius: '30px',
        display: 'block',
        boxShadow: theme => `10px 24px 54px 0px ${alpha(theme.palette.common.black, 0.05)}`,

        '& img': {
          transition: 'transform 0.3s ease-in-out',
        },

        '&:hover': {
          boxShadow: theme => `0px 12px 16px 0px ${alpha(theme.palette.common.black, 0.3)}`,
          '& img': {
            transform: 'scale(1.05)',
          },
        },
      }}
    >
      <CardMedia
        component="img"
        sx={{ height: { xs: 350, md: 500 }, width: '100%' }}
        image={profilePicture}
        alt={fullName}
      />
      <CardContent
        sx={{
          position: 'absolute',
          bottom: 0,
          color: theme => theme.palette.common.white,
          width: 'calc(100% - 32px)',
          borderRadius: '0px, 0px, 30px, 30px',
          background: theme => `linear-gradient(to bottom, transparent, ${theme.palette.primary.main} )`,
          height: '170px',
          alignContent: 'end',
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: theme => theme.palette.common.white,
            width: '100%',
            mb: 1,
          }}
        >
          {fullName}
        </Typography>

        <Tooltip title={role}>
          <Typography
            variant="body2"
            sx={{
              color: theme => theme.palette.grey[100],
              ...ellipsis,
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: `${role}` }} />
          </Typography>
        </Tooltip>
      </CardContent>
    </Card>
  );
};
