import Container from '@mui/material/Container';
import { TSlideshowAppEnum } from '@onesource/schemas';
import { TSlideProps, useGetSlideshowsQuery } from '@onesource/services';
import { formatDateWithTime } from '@onesource/utils';
import { FC, useMemo } from 'react';
import { HeroSlider } from '../heroslider';
import { SliderLoading } from '../heroslider/loading';
import { LoadingError } from '../loading-error';
import { NotFoundCard } from '../not-found';

type TProps = {
  app?: TSlideshowAppEnum;
};

export const OneSourceSlideShow: FC<TProps> = props => {
  const { app } = props;

  const {
    data: slides,
    isLoading: isSlidesLoading,
    refetch,
    error,
  } = useGetSlideshowsQuery({
    filters: {
      app,
      isActive: true,
    },
    refetchInterval: 1000 * 15,
  });

  const slidesMemo = useMemo(() => {
    if (isSlidesLoading) {
      return [];
    }

    const data = slides?.items;
    if (!data) {
      return [];
    }

    return data.map(item => {
      const {
        title,
        subtitle,
        description,
        image,
        buttonUrl,
        buttonLabel,
        badgeImage,
        badgeImagePosition,
        event,
        content,
        type,
        series,
        vimeoVideoId,
      } = item;
      const classDate = event?.scheduleDateTime.toString() ?? content?.publishedDate.toString() ?? null;

      const href =
        buttonUrl ??
        (event && `/events/${event?.id}`) ??
        (content && `/classes/details/${content?.id}`) ??
        (series && `/series/${series.id}`) ??
        '#';
      const label =
        buttonLabel ?? (event && 'Join live') ?? (content && content?.name) ?? (series && series.name) ?? '';

      const eventSubtitle = event && `with ${event.instructor.firstName} ${event.instructor.lastName}`;
      const eventDescription = event && `${formatDateWithTime(event.scheduleDateTime.toString())}`;

      const prop: TSlideProps = {
        type,
        ...(type === 'event' ? { status: event?.status } : {}),
        sectionTitle: title,
        sectionSubtitle: eventSubtitle ?? subtitle ?? '',
        sectionDescription: eventDescription ?? description ?? '',
        badgeImage,
        badgeImagePosition,
        bgImg: image ?? '',
        vimeoVideoId: vimeoVideoId ?? '',
        ...(classDate ? { classDate } : {}),
        actionButton:
          !!label && !!href
            ? {
                href,
                children: label,
              }
            : undefined,
      };

      return prop;
    });
  }, [isSlidesLoading, slides]);

  if (error) {
    return (
      <Container maxWidth="xl">
        <LoadingError
          entity="Slideshow"
          message={error.message}
          errorCode={error.code}
          refetch={refetch}
          sx={{ mt: 8 }}
        />
      </Container>
    );
  }

  if (isSlidesLoading) {
    return <SliderLoading />;
  }

  if (!slidesMemo.length) {
    return (
      <Container maxWidth="xl">
        <NotFoundCard sx={{ mt: 8 }} entity="Slideshow" />
      </Container>
    );
  }

  return <HeroSlider slides={slidesMemo} />;
};
