import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { alpha, SxProps, useTheme } from '@mui/material';
import Container from '@mui/material/Container';
import { MvPlay } from '@onesource/icons';
import { TSlideProps } from '@onesource/services';
import { useSlideBadgeImage } from '../use-slide-badge-image';

type THeroPlaylistSlideProps = TSlideProps & {
  customize?: {
    actionButtonSx?: SxProps;
  };
};

export const HeroPlaylistSlide: FC<THeroPlaylistSlideProps> = props => {
  const {
    sectionTitle,
    sectionSubtitle,
    sectionDescription,
    actionButton,
    bgImg,
    customize,
    badgeImage,
    badgeImagePosition,
  } = props;
  const theme = useTheme();

  const { BadgeImage, containerAfterCSS } = useSlideBadgeImage({
    bgImg,
    badgeImage,
    badgeImagePosition,
  });

  const slideType = 'dark_variant';

  const slideInfoSection = {
    pink_variant: {
      color: theme.palette.common.black,
      background: {
        md: 'linear-gradient(100.75deg, #FCD9DE 56.43%, rgba(255, 192, 192, 0.9) 86.68%)',
        xs: `linear-gradient(to left, transparent ,${theme.palette.common.black})`,
      },
    },
    dark_variant: {
      color: theme.palette.common.white,
      background: {
        md: 'linear-gradient(90deg, #323232 76.8%, rgba(0, 0, 0, 0.52) 93.65%)',
        xs: `linear-gradient(to left, transparent ,${theme.palette.common.black})`,
      },
    },
  };
  const textColor = slideInfoSection[slideType].color;

  return (
    <Box
      sx={{
        position: 'relative',
        backgroundRepeat: 'no-repeat ',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: `url(${bgImg})`,
        width: '100%',
        minHeight: {
          xs: '468px',
          md: '725px',
        },
        overflow: 'hidden',
        pt: { xs: 0, md: 11 },
        '&:before': {
          content: "''",
          position: 'absolute',
          width: {
            xs: '100%',
            md: '1000px',
          },
          height: {
            xs: '600px',
            md: '1000px',
          },
          top: '-90px',
          left: { xs: '0px', md: '-343px' },
          background: slideInfoSection[slideType].background,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          outline: { xs: '0px', md: `25px solid ${alpha(theme.palette.grey[600], 0.5)}` },
          borderRadius: { xs: '0px', md: '50%' },
        },
        ...containerAfterCSS,
      }}
    >
      <BadgeImage />

      <Container maxWidth="xl" sx={{ position: 'relative', zIndex: 1, overflow: 'hidden' }}>
        <Grid container spacing={4}>
          <Grid
            item
            md={4}
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              minHeight: {
                xs: '400px',
                md: '725px',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                minHeight: '350px',
                pt: { xs: 6 },
                pb: { xs: 6 },
              }}
            >
              <Box>
                <Typography
                  variant="h2"
                  sx={{
                    mt: {
                      xs: 0,
                      md: 9,
                    },
                    fontSize: { xs: '30px', md: '48px' },
                    fontWeight: { xs: '700', md: '600' },
                    mb: { xs: 1.5, md: 3 },
                    color: textColor,
                    '& br': { display: { xs: 'none', md: 'block' } },
                  }}
                >
                  <span dangerouslySetInnerHTML={{ __html: sectionTitle }} />
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: '24px',
                    fontWeight: '600',
                    mb: 2,
                    color: textColor,
                  }}
                >
                  <span dangerouslySetInnerHTML={{ __html: sectionSubtitle }} />
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: '16px',
                    fontWeight: '400',
                    mb: 3,
                    color: textColor,
                    lineHeight: 1.6,
                  }}
                >
                  <span dangerouslySetInnerHTML={{ __html: sectionDescription }} />
                </Typography>
              </Box>
              {actionButton && (
                <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' } }}>
                  <Button
                    endIcon={
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '50px',
                          width: '50px',
                          borderRadius: '50%',
                          background: theme => theme.palette.primary.main,
                          color: theme => theme.palette.common.white,
                          boxShadow: `5px 5px 15px ${theme.palette.common.black}20`,
                        }}
                      >
                        <MvPlay />
                      </Box>
                    }
                    {...actionButton}
                    sx={{ paddingLeft: '20px', ...customize?.actionButtonSx }}
                  >
                    {actionButton.children}
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
