import axios from 'axios';
import { API_BASE_URL } from '../config';

export type TLeaderBoardUser = {
  id: string;
  name: string;
  email: string;
  role: string;
};

export type TSetSession = {
  token: string;
  user: {
    email: string;
    name: string;
  };
};

export const axiosAbortController = new AbortController();

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  signal: axiosAbortController.signal,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  res => res,
  error => {
    return Promise.reject((error.response && error.response.data) || 'something went wrong');
  },
);

export const setTokenToAxios = (token: string) => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const removeTokenFromAxios = () => {
  delete axiosInstance.defaults.headers.common.Authorization;
};

export default axiosInstance;
export const axiosServiceInstance = axiosInstance;
