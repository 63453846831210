import { darken, SxProps, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { MvPlay } from '@onesource/icons';
import {
  ECompleteContentAction,
  IndicatorEnum,
  TAppNameEnum,
  TCompleteContentAction,
  TContent,
  TContentRatingSet,
} from '@onesource/schemas';
import { useCompleteContentMutation, useGetContentRatingQuery, useSaveContentRatingQuery } from '@onesource/services';
import { Link } from '@tanstack/react-router';
import Player from '@vimeo/player';
import { FC, ReactNode, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { VideoPlayer } from '../video-player';
import { useGetByDevice } from '../hooks';
import { CommentProps } from '../cards';
import { BackButton } from '../back-button';
import { CommentsSection } from '../comments';
import { Ratings } from '../ratings';
import { RequirementIndicator } from '../requirement-indicator';

export type VideoSectionProps = {
  app: TAppNameEnum;
  content: TContent;
  sx?: SxProps;
  isDemo?: boolean;
  onClickAddPlaylist?: (anchorEl: HTMLElement, contentId: string) => void;
};

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

export const VideoSection: FC<VideoSectionProps> = props => {
  const { content, sx, isDemo, app } = props;
  const [value, setValue] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [sentAction, setSentAction] = useState<TCompleteContentAction | null>(null);
  const [updatedRating, setUpdatedRating] = useState<TContentRatingSet>({
    [IndicatorEnum.difficulty]: 2,
    [IndicatorEnum.intensity]: 2,
    [IndicatorEnum.nerdiness]: 2,
  });

  const { isMobile } = useGetByDevice();
  const theme = useTheme();

  const { mutateAsync: saveRatingMutation, isPending: isRatingSavePending } = useSaveContentRatingQuery();
  const { data: contentRatingData } = useGetContentRatingQuery({
    contentId: content.id,
  });
  const { mutateAsync: completeContentMutation } = useCompleteContentMutation();

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [comments, setComments] = useState<Array<CommentProps & { id: string | number }>>([
    {
      id: 1,
      avatarUrl: 'https://i.pravatar.cc/300',
      username: 'John Doe',
      date: '2 weeks ago',
      comment: 'Great video, very informative!',
    },
    {
      id: 2,
      avatarUrl: 'https://i.pravatar.cc/300',
      username: 'Janny Keplar',
      date: '2 weeks ago',
      comment: 'Another Great video, very informative!',
    },
  ]);

  const completeContentHandler = useCallback(
    async (contentId: string, action: TCompleteContentAction) => {
      await completeContentMutation({ contentId, action, app });
    },
    [app],
  );

  const handleSendComment = useCallback((comment: string) => {
    setComments(prev => [
      ...prev,
      {
        avatarUrl: 'https://i.pravatar.cc/300',
        id: prev.length + 1,
        username: 'You',
        date: 'Just now',
        comment: comment,
      },
    ]);
  }, []);

  const handleChange = useCallback((_event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  }, []);

  const handleOpenModal = useCallback(() => {
    if (isDemo) {
      return;
    }
    setModalOpen(() => true);
  }, []);

  useEffect(() => {
    if (iframeRef.current) {
      const player = new Player(iframeRef.current);

      player.on('ended', async () => {
        player.exitFullscreen().then(() => {
          setModalOpen(true);
        });
      });

      player.on('timeupdate', async () => {
        const totalTime = await player.getDuration();

        const currentTime = await player.getCurrentTime();
        const secondsPerMin = 60;
        const flooredCurrentTime = Math.floor(currentTime);

        const minToSecondsFor2 = 2 * secondsPerMin;
        const minToSecondsFor5 = 5 * secondsPerMin;

        const isTwoMinWatched = flooredCurrentTime > minToSecondsFor2 && sentAction === null;
        const isFiveMinWatched =
          flooredCurrentTime > minToSecondsFor5 && sentAction === ECompleteContentAction['2min_watch'];

        const isLastMinRemains =
          totalTime - currentTime < secondsPerMin && sentAction === ECompleteContentAction['5min_watch'];

        if (isTwoMinWatched) {
          setSentAction(ECompleteContentAction['2min_watch']);
          completeContentHandler(content.id, ECompleteContentAction['2min_watch']);
        }

        if (isFiveMinWatched) {
          setSentAction(ECompleteContentAction['5min_watch']);
          completeContentHandler(content.id, ECompleteContentAction['5min_watch']);
        }

        if (isLastMinRemains) {
          setSentAction(ECompleteContentAction['1min_before_finish']);
          completeContentHandler(content.id, ECompleteContentAction['1min_before_finish']);
        }
      });

      return () => {
        player.off('ended');
        player.off('timeupdate');
      };
    }
  }, [iframeRef, completeContentHandler, sentAction, content.id]);

  const handleClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleRatingChange = useCallback((indicatorKey: string, newRating: number) => {
    setUpdatedRating(prev => ({
      ...prev,
      [indicatorKey]: newRating,
    }));
  }, []);

  const handleRatingSubmitHandler = useCallback(async () => {
    const data = await saveRatingMutation({
      contentId: content.id,
      rating: updatedRating,
    });

    setUpdatedRating(() => data);
    handleClose();
  }, [updatedRating]);

  useEffect(() => {
    if (contentRatingData?.userScore) {
      setUpdatedRating(contentRatingData.userScore);
    }
  }, [contentRatingData?.userScore]);

  const videoPlayerProps = useMemo(
    () => ({
      ...props,
      iframeRef,
    }),
    [props],
  );

  const isRatingButtonDisabled = useMemo(() => {
    const isAllRatingSelected = Object.values(updatedRating).every(Boolean);

    return isRatingSavePending || !isAllRatingSelected;
  }, [isRatingSavePending, updatedRating]);

  return (
    <Box sx={sx}>
      <Container maxWidth="xl">
        <Box sx={{ position: 'relative', zIndex: 1 }}>
          <Grid container spacing={isDemo ? 2 : 4}>
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
              {!isDemo && (
                <Box sx={{ flex: 1 }}>
                  <BackButton color="black" />
                </Box>
              )}
              <Box sx={{ textAlign: 'center', flex: 11 }}>
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1,
                    textAlign: 'center',
                    lineHeight: '1.5',
                    color: darken(theme.palette.common.white, 0.799),
                  }}
                >
                  {content.name}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: 'center',
                    color: darken(theme.palette.common.white, 0.799),
                  }}
                >
                  <Link
                    style={{
                      color: darken(theme.palette.common.white, 0.799),
                      textDecoration: 'none',
                    }}
                    to={`/instructors/${content.instructor.id}`}
                  >
                    {content.instructor.firstName} {content.instructor.lastName}
                  </Link>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <VideoPlayer {...videoPlayerProps} />
            </Grid>

            <Grid item xs={12} md={9}>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="About" />
                    {/* <Tab label="Comments" {...a11yProps(1)} /> */}
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <Typography
                    variant="body2"
                    sx={{
                      mb: 3,
                      color: darken(theme.palette.common.white, 0.799),
                    }}
                  >
                    <span dangerouslySetInnerHTML={{ __html: content.video.description }} />
                  </Typography>
                  <Box sx={{ width: '100%', mb: { xs: 0, md: 5 } }}>
                    {content.equipments.map(item => (
                      <Chip
                        key={item.slug}
                        label={item.name}
                        sx={{
                          mr: 1,
                          mb: 1,
                          px: 2,
                          height: '28px',
                          backgroundColor: theme => theme.palette.grey[800],
                          color: 'white',
                        }}
                        size="small"
                      />
                    ))}
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <CommentsSection handleSendComment={handleSendComment} comments={comments} />
                </CustomTabPanel>
              </Box>
            </Grid>
            <Grid item md={3} xs={12}>
              <Ratings openModal={handleOpenModal} title="Ratings" post={content} />
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Modal open={isModalOpen} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: { xs: '90%', md: '50%' },
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            borderRadius: '30px',
            boxShadow: 24,
            background: 'F7F7F7',
            display: 'flex',
            gap: 2,
            justifyContent: 'space-around',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '30px 30px 0 0',
                flex: 1,
                px: 2,
                py: 1,
                backgroundColor: { xs: 'primary.main', md: 'white' },
              }}
            >
              <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
                <Grid item xs={10} md={11}>
                  <Typography
                    variant="body1"
                    color="inherit"
                    sx={{ fontWeight: '600', display: { xs: 'none', md: 'block' } }}
                  >
                    Congratulations on completing the class!
                  </Typography>
                  <Typography
                    variant={isMobile ? 'body1' : 'body2'}
                    sx={{
                      color: { xs: theme.palette.common.white, md: theme.palette.common.black },
                      fontWeight: { xs: '700', md: '400' },
                    }}
                  >
                    {isMobile
                      ? 'Please add your own rating'
                      : 'Your feedback helps us create a valuable and informative resource for our community—please take a moment to share your rating.'}
                  </Typography>
                </Grid>
                <Grid item xs={2} md={1} sx={{ textAlign: 'right' }}>
                  <IconButton size="large" onClick={handleClose} color="primary">
                    <IoIosCloseCircleOutline
                      color={isMobile ? theme.palette.common.white : theme.palette.common.black}
                      size={32}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>

            <Divider sx={{ width: '95%', margin: '0 auto' }} />

            <Box sx={{ flex: 1, p: 3 }}>
              {isRatingSavePending && (
                <Box
                  sx={{
                    position: 'absolute',
                    height: `calc(100% - 2px)`,
                    width: `calc(100% - 2px)`,
                    top: 1,
                    left: 1,
                    zIndex: 1,
                    borderRadius: '10px',
                    background: 'rgba(0,0,0,0.2)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <CircularProgress size={60} />
                </Box>
              )}

              <Stack
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: { xs: 'column', md: 'initial' },
                  '&>div': { flex: '0 0 50%', boxSizing: 'border-box', mb: 3 },
                  '&>div:nth-of-type(odd)': { paddingRight: { xs: 0, md: 6.5 } },
                  '&>div:nth-of-type(even)': { paddingLeft: { xs: 0, md: 6.5 } },
                }}
              >
                <RequirementIndicator
                  indicator={IndicatorEnum.difficulty}
                  showName={true}
                  isInteractive={true}
                  onChange={newRating => handleRatingChange(IndicatorEnum.difficulty, newRating)}
                  point={updatedRating[IndicatorEnum.difficulty]}
                />

                <RequirementIndicator
                  indicator={IndicatorEnum.intensity}
                  showName={true}
                  isInteractive={true}
                  onChange={newRating => handleRatingChange(IndicatorEnum.intensity, newRating)}
                  point={updatedRating[IndicatorEnum.intensity]}
                />

                <RequirementIndicator
                  indicator={IndicatorEnum.nerdiness}
                  showName={true}
                  isInteractive={true}
                  onChange={newRating => handleRatingChange(IndicatorEnum.nerdiness, newRating)}
                  point={updatedRating[IndicatorEnum.nerdiness]}
                />
              </Stack>
            </Box>

            <Box sx={{ pb: 3, px: 3, display: 'flex', justifyContent: 'center' }}>
              <Button
                fullWidth={isMobile}
                disabled={isRatingButtonDisabled}
                onClick={handleRatingSubmitHandler}
                endIcon={
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '50px',
                      width: '50px',
                      borderRadius: '50%',
                      background: theme =>
                        isRatingButtonDisabled ? theme.palette.grey[800] : theme.palette.primary.main,
                      color: theme => theme.palette.common.white,
                      boxShadow: `5px 5px 15px ${theme.palette.common.black}20`,
                    }}
                  >
                    <MvPlay />
                  </Box>
                }
                sx={{ paddingLeft: '20px', width: { md: '30%' } }}
              >
                Rate
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
