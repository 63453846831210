import { ButtonProps } from '@mui/material';
import { AddEditPlaylistDialog, CommonSlideshow } from '@onesource/components';
import { ContentFilterTypeEnum, EAppNameEnum, TContentFilterType, TContentRequest } from '@onesource/schemas';
import { useGetContentsQuery, useGetSubscriptionQuery } from '@onesource/services';
import { usePlaylistMenuService } from 'components/add-to-playlist/service';
import { usePlaylistAction } from 'hooks/use-playlist-actions';
import { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import { TextFilterNav } from '../text-filter-nav';

export type TMostViewed = {
  sectionTitle: string;
  actionButton?: ButtonProps;
};

export const MostViewed: FC<TMostViewed> = props => {
  const { sectionTitle } = props;
  const { isFitSubscriptionActive } = useGetSubscriptionQuery();
  const [filters, setFilters] = useState<TContentRequest>({
    type: ContentFilterTypeEnum.mostViewedWeekly,
    perPage: 10,
  });

  const {
    contextClassId,
    onAddToPlaylist,
    createPlaylist,
    isCreatePending,
    onClosePlaylistDialog,
    onClickCreate,
    isCreatePlaylistDialogOpen,
  } = usePlaylistAction();

  const { setPlaylistMenu } = usePlaylistMenuService();
  const { data: mostPopularRes, isLoading } = useGetContentsQuery({ filters, app: EAppNameEnum.FIT });

  const onClickAddPlaylistHandler = useCallback(
    (anchorEl: HTMLElement, contentId: string) => {
      setPlaylistMenu({
        contentId,
        anchorEl,
        onAddToPlaylist,
        onClickCreate,
      });
    },
    [onAddToPlaylist, onClickCreate, onClosePlaylistDialog],
  );

  const slides = useMemo(() => mostPopularRes?.items || [], [mostPopularRes]);

  const handleFilter = useCallback((_event: MouseEvent<HTMLElement>, value: string) => {
    setFilters(prev => ({
      ...prev,
      type: value as TContentFilterType,
    }));
  }, []);

  const textFilterNav = () => (
    <TextFilterNav
      filter={filters.type}
      handleFilter={handleFilter}
      items={[
        { label: 'Last 7 Days', value: ContentFilterTypeEnum.mostViewedWeekly },
        { label: 'Last 30 Days', value: ContentFilterTypeEnum.mostViewedMonthly },
      ]}
    />
  );

  return (
    <>
      <CommonSlideshow
        slides={slides}
        isLoading={isLoading}
        sectionTitle={sectionTitle}
        onClickAddPlaylistHandler={onClickAddPlaylistHandler}
        filterNavElement={textFilterNav()}
        isSubscriptionActive={isFitSubscriptionActive}
      />
      <AddEditPlaylistDialog
        isPending={isCreatePending}
        open={isCreatePlaylistDialogOpen}
        onClose={onClosePlaylistDialog}
        onSave={createPlaylist}
        contentId={contextClassId}
      />
    </>
  );
};
