import Grid, { GridProps } from '@mui/material/Grid';
import { FC, ReactNode } from 'react';

type TProps = GridProps & {
  children: ReactNode | ReactNode[];
  horizontalScroll?: boolean;
};

export const ScrollContainer: FC<TProps> = props => {
  const { children, sx, horizontalScroll, ...rest } = props;

  return (
    <Grid
      sx={{
        ...(horizontalScroll
          ? {
              overflowX: 'scroll',
              '--mask-deg': '90deg',
            }
          : {
              overflowY: 'scroll',
              '--mask-deg': '0deg',
            }),
        height: 'calc(100vh - 175px)',
        scrollBehavior: 'smooth',
        '--mask-end-percentage': 'calc(100% - var(--mask-start-percentage))',
        '--mask-from-and-end-color': 'transparent',
        '--mask-start-percentage': '7%',

        '--mask-property':
          'linear-gradient(var(--mask-deg), var(--mask-from-and-end-color), var(--mask-color) var(--mask-start-percentage), var(--mask-color) var(--mask-end-percentage), var(--mask-from-and-end-color))',

        '-webkit-mask': 'var(--mask-property)',
        mask: 'var(--mask-property)',
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Grid>
  );
};
