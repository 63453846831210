import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {
  AddEditPlaylistDialog,
  Content,
  DataStateHandler,
  PostFilterSidebar,
  PostLoading,
  useGetByDevice,
  useSetSearchParams,
} from '@onesource/components';
import { Cardio, Restore, Strength, YogaPose } from '@onesource/icons';
import { EAppNameEnum, TContent, contentFilterDefaultValues } from '@onesource/schemas';
import { useGetContentsQuery, useGetSubscriptionQuery, useGetUserWatchedContentIDsQuery } from '@onesource/services';
import { theme } from '@onesource/theme';
import { usePlaylistMenuService } from 'components/add-to-playlist/service';
import { usePlaylistAction } from 'hooks/use-playlist-actions';
import { FC, useCallback, useEffect } from 'react';
import { FeaturedNav } from '../../components/featured-nav';
import { useRouteUtils } from '../../components/use-route-utils';
import { ClassesHeader } from 'components/sections/classes-header';

export const ClassesByCategory: FC = () => {
  const { searchParams } = useRouteUtils();
  const { isPortable } = useGetByDevice();
  // const { category } = useClassDetailsPageSearchParams();

  const {
    contextClassId,
    onAddToPlaylist,
    createPlaylist,
    isCreatePending,
    onClosePlaylistDialog,
    onClickCreate,
    isCreatePlaylistDialogOpen,
  } = usePlaylistAction();

  const searchFilterUtils = useSetSearchParams({
    initialFilters: { ...contentFilterDefaultValues, ...searchParams, scrollTop: '1' },
    resetFilters: { ...contentFilterDefaultValues, scrollTop: '1' },
  });

  const { patchFilter, filters } = searchFilterUtils;

  const { categoryId } = filters;

  const { data: watchedContents } = useGetUserWatchedContentIDsQuery();
  const contentStates = useGetContentsQuery({ filters: searchFilterUtils.filters, app: EAppNameEnum.FIT });

  const { isFitSubscriptionActive } = useGetSubscriptionQuery();

  const onClickCategoryHandler = useCallback((categoryId: string) => {
    patchFilter({ categoryId });
  }, []);

  const onNext = useCallback((page: number) => {
    patchFilter({ page: page.toString() });
  }, []);

  const onPrev = useCallback((page: number) => {
    patchFilter({ page: page.toString() });
  }, []);

  const defaultSelectCategoryId = 'STRENGTH';

  useEffect(() => {
    if (!filters.categoryId && defaultSelectCategoryId) {
      patchFilter({ categoryId: defaultSelectCategoryId });
    }
  }, []);

  return (
    <>
      <ClassesHeader searchFilterUtils={searchFilterUtils} />
      <Box sx={{ background: theme => theme.palette.common.white }}>
        <FeaturedNav
          nav={[
            {
              name: 'STRENGTH',
              selected: categoryId === 'STRENGTH',
              onClick: onClickCategoryHandler,
              startIcon: <Strength color={theme.palette.grey[800]} width="42px" height="42px" />,
            },
            {
              name: 'FLOW',
              selected: categoryId === 'FLOW',
              onClick: onClickCategoryHandler,
              startIcon: <YogaPose color={theme.palette.grey[800]} width="42px" height="42px" />,
            },
            {
              name: 'CARDIO',
              selected: categoryId === 'CARDIO',
              onClick: onClickCategoryHandler,
              startIcon: <Cardio color={theme.palette.grey[800]} width="42px" height="42px" />,
            },
            {
              name: 'RESTORE',
              selected: categoryId === 'RESTORE',
              onClick: onClickCategoryHandler,
              startIcon: <Restore color={theme.palette.grey[800]} width="42px" height="42px" />,
            },
          ]}
          sx={{ py: { xs: 4, md: 2 }, pb: { xs: 4, md: 5 } }}
        />

        <Container maxWidth="xl" sx={{ my: { xs: 0, md: 6 } }}>
          <Grid container spacing={{ xs: 0, md: 2 }}>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={{ xs: 2, md: 5 }}>
                <Grid item md={3} sm={6} xs={12} sx={{ display: isPortable ? 'none' : 'block' }}>
                  <PostFilterSidebar searchFilterUtils={searchFilterUtils} hide={['category']} />
                </Grid>
                <Grid item md={12} lg={9} sm={12} xs={12}>
                  <DataStateHandler
                    queryResult={contentStates}
                    onNext={onNext}
                    onPrev={onPrev}
                    entity="classes by category"
                    loadingProps={{
                      loadingWrapperProps: {
                        flexDirection: 'row',
                      },
                      loadingItemProps: {
                        sm: 4,
                        md: 4,
                        xs: 12,
                      },
                    }}
                    gridProps={{ md: 4, sm: 6, xs: 12 }}
                    pagination={!!contentStates.data?.meta.totalPages}
                    LoadingComponent={PostLoading}
                    IterativeComponent={(props: { item: TContent }) => {
                      // INFO: we need to fix this
                      const { setPlaylistMenu } = usePlaylistMenuService();

                      const onClickAddPlaylistHandler = useCallback(
                        (anchorEl: HTMLElement, contentId: string) => {
                          setPlaylistMenu({
                            contentId,
                            anchorEl,
                            onAddToPlaylist,
                            onClickCreate,
                          });
                        },
                        [onAddToPlaylist, onClickCreate, onClosePlaylistDialog],
                      );

                      return (
                        <Content
                          hasWatched={!!watchedContents?.has(props.item.id)}
                          linkProps={{
                            search: {
                              category: props.item.category,
                            },
                          }}
                          content={props.item}
                          isLocked={!isFitSubscriptionActive}
                          onClickAddPlaylist={onClickAddPlaylistHandler}
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>

        <AddEditPlaylistDialog
          isPending={isCreatePending}
          open={isCreatePlaylistDialogOpen}
          onClose={onClosePlaylistDialog}
          onSave={createPlaylist}
          contentId={contextClassId}
        />
      </Box>
    </>
  );
};
