import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { ellipsis } from '@onesource/utils';

type TItem = {
  id: string;
  label: string;
};

export interface ISidebarRadioGroupFilterProps<T extends TItem> {
  onChange: (name: string, value: string) => void;
  items: T[];
  name: string;
  value: string;
}

export function SidebarFilterRadioGroup<T extends TItem>(props: ISidebarRadioGroupFilterProps<T>) {
  const { items, onChange, name, value } = props;

  return (
    <RadioGroup key={name} name={name} value={value || null} onChange={(_e, value) => onChange(name, value)}>
      <Grid container spacing={0}>
        {items.map(option => (
          <Grid key={option.id} xs={6} item>
            <FormControlLabel
              value={option.id}
              checked={value === option.id}
              sx={{
                maxWidth: '100%',
                height: 34,
                '& .MuiFormControlLabel-label': {
                  ...ellipsis,
                  color: theme => theme.palette.grey[800],
                  fontSize: '13px',
                  fontWeight: 400,
                },
              }}
              control={<Radio size="small" sx={{ color: theme => theme.palette.grey[800] }} />}
              label={option.label}
            />
          </Grid>
        ))}
      </Grid>
    </RadioGroup>
  );
}
