import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import { TEvent } from '@onesource/schemas';
import Player from '@vimeo/player';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { DialogTitle } from '@onesource/components';

export type VideoSectionProps = {
  event: TEvent;
  sx?: SxProps;
};

export const LiveClass: FC<VideoSectionProps> = props => {
  const { event, sx } = props;
  const [isEventCompleteDialogOpen, setEventCompleteDialogOpen] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (iframeRef.current) {
      const player = new Player(iframeRef.current);

      player.on('ended', () => {
        setEventCompleteDialogOpen(true);
      });

      return () => {
        player.off('ended');
      };
    }
  }, []);

  const handleClose = useCallback(() => {
    setEventCompleteDialogOpen(false);
  }, []);

  return (
    <Box sx={{ ...sx }}>
      <Box sx={{ position: 'relative', zIndex: 1 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box sx={{ mb: 4 }}>
              <Card
                raised
                sx={{
                  position: 'relative',
                  borderRadius: { xs: '10px', md: '30px' },
                  margin: '0 auto',
                  boxShadow: 'none',
                  overflow: 'hidden',
                  maxWidth: '100%',
                  background: 'transparent',
                  padding: '56.25% 0 0 0',
                }}
              >
                <CardMedia
                  component="iframe"
                  src={event.videoStreamUrl}
                  allow="autoplay"
                  allowFullScreen
                  ref={iframeRef}
                  sx={{
                    maxHeight: '700px',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                    border: 0,
                    borderRadius: 'inherit',
                  }}
                />
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Dialog maxWidth="xs" open={isEventCompleteDialogOpen} onClose={handleClose}>
        <DialogTitle onClose={handleClose}>Event ended</DialogTitle>
        <DialogContent>
          <DialogContentText>Congratulations for doing this great class! See you in the next live.</DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
