import { useQuery } from '@tanstack/react-query';
import { ENTITY } from './entity';
import axios from '../axios';
import { TError, TSettingsSchema } from '@onesource/schemas';

interface IGetContentScheduleSettingsParams {
  type: string;
}

export const getSettingsQueryKey = () => {
  return [ENTITY];
};

export const useGetSettingsQuery = (param: IGetContentScheduleSettingsParams) => {
  const { type } = param;
  return useQuery<TSettingsSchema, TError>({
    queryKey: getSettingsQueryKey(),
    queryFn: async () => {
      const res = await axios.get<TSettingsSchema>(`${ENTITY}/${type}`);
      const data = res.data;
      return data;
    },
  });
};
