import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export type TRFHAuthcompleteOption = {
  id: string;
  label: string;
  value: string | number;
  [key: string]: string | number | boolean | undefined;
};

type TProps = TextFieldProps & {
  name: string;
  maxHeight?: boolean | number;
  fullWidth?: boolean;
  options: TRFHAuthcompleteOption[];
  renderLabelExtension?: (option: TRFHAuthcompleteOption) => JSX.Element | null;
};

export const RHFAutoComplete: FC<TProps> = props => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { name, helperText, options, autoComplete: _autoComplete, renderLabelExtension, ...rest } = props;
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const { ref } = field;

        return (
          <Autocomplete
            {...field}
            fullWidth
            value={options.find(option => Number(option.value) === Number(field.value)) ?? null}
            onChange={(_, value) => {
              field.onChange(value ? value.value : null);
            }}
            options={options}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            getOptionLabel={(option: TRFHAuthcompleteOption) => option.label} // Ensure option is of type TOption
            renderInput={params => (
              <TextField
                {...params}
                inputRef={ref}
                fullWidth
                error={!!error}
                helperText={error ? error.message : helperText}
                label={rest.label}
                autoComplete="off"
              />
            )}
            renderOption={(props, option, { selected }) => (
              <Box
                component="li"
                {...props}
                sx={{
                  color: theme => (selected ? theme.palette.primary.main : theme.palette.common.black),
                }}
                key={option.id}
              >
                <Stack direction="row" alignItems="center" spacing={2} sx={{ width: '100%' }}>
                  {option.label}{' '}
                  <Box component="span" sx={{ pl: 2 }}>
                    {renderLabelExtension?.(option)}
                  </Box>
                </Stack>
              </Box>
            )}
          />
        );
      }}
    />
  );
};
