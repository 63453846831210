import { TError, TSeriesResponse } from '@onesource/schemas';
import { useQuery } from '@tanstack/react-query';
import axios from '../axios';
import { ENTITY } from './entity';

interface IGetSeriesParams {
  seriesId: string;
  enabled?: boolean;
}

export const getSeriesQueryKey = (seriesId: string) => {
  return [ENTITY, seriesId];
};

export const useGetSeriesQuery = (param: IGetSeriesParams) => {
  const { seriesId, enabled } = param;

  return useQuery<TSeriesResponse, TError>({
    queryKey: getSeriesQueryKey(seriesId),
    queryFn: async () => {
      const res = await axios.get<TSeriesResponse>(`${ENTITY}/${seriesId}`);
      return res.data;
    },
    enabled,
    refetchOnWindowFocus: false,
  });
};
