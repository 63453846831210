import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

import { ellipsis } from '@onesource/utils';
import { useGetByDevice } from '../../hooks';

export const EventCardLoading: FC = () => {
  const { isMobile } = useGetByDevice();

  return (
    <Card
      sx={{
        borderRadius: 4,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        minHeight: '200px',
        boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      }}
    >
      <Grid container spacing={2}>
        <Grid md={4} xs={12} item>
          <Skeleton variant="rectangular" height="100%" />
        </Grid>

        <Grid md={8} xs={12} item>
          <CardContent sx={{ p: 3 }}>
            <Box>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h5" component="h2" sx={{ mb: 1, fontWeight: 'bold', ...ellipsis }}>
                  <Skeleton width={isMobile ? 170 : 200} height={40} />
                </Typography>

                <Stack spacing={2} direction="row">
                  <Skeleton width={isMobile ? 60 : 100} />
                  <Skeleton width={isMobile ? 60 : 100} />
                </Stack>
              </Stack>

              <Stack direction="row">
                <Typography variant="body2" color="textSecondary" mb={0.5} fontStyle="italic">
                  <Skeleton width={isMobile ? 100 : 150} />
                </Typography>
              </Stack>

              <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </Typography>

              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" spacing={1} alignItems="center">
                  <Skeleton variant="rectangular" width={50} height={30} />
                </Stack>

                <Skeleton variant="rectangular" width={isMobile ? 90 : 100} height={40} />
              </Stack>
            </Box>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};
